import { useState } from 'react';
import PropTypes from 'prop-types';
import Icon from '@ui/components/Icon/Icon';
import Tooltip from '../Tooltip/Tooltip';

import './InformationTooltipIcon.scss';
import { SECONDARY_BASE } from '../../../styles/colors';

export const InformationTooltipIcon = ( {
	title,
	position,
	idleIcon,
	hoverIcon,
	color,
	zIndex,
	html
} ) => {
	const [ icon, setIcon ] = useState( idleIcon );
	const [ tooltipOpen, setTooltip ] = useState( false );
	return (
		<div
			className="InformationTooltipIcon"
			aria-label="Information Tooltip Icon"
			onMouseEnter={() => {
				setTooltip( true );
				setIcon( hoverIcon );
			}}
			onMouseLeave={() => {
				setTooltip( false );
				setIcon( idleIcon );
			}}
		>
			<Tooltip
				title={title}
				position={position}
				open={tooltipOpen}
				zIndex={zIndex}
				html={html}
			>
				<Icon icon={icon} size={20} color={color} />
			</Tooltip>
		</div>
	);
};

InformationTooltipIcon.propTypes = {
	title: PropTypes.string,
	position: PropTypes.string,
	idleIcon: PropTypes.string,
	hoverIcon: PropTypes.string,
	color: PropTypes.string,
	zIndex: PropTypes.number,
	html: PropTypes.any
};

InformationTooltipIcon.defaultProps = {
	title: undefined,
	position: undefined,
	idleIcon: 'info_outlined',
	hoverIcon: 'info',
	color: SECONDARY_BASE,
	zIndex: undefined,
	html: undefined
};

export default InformationTooltipIcon;
