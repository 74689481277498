import { notifications as mantineNotifications } from '@mantine/notifications';
import Icon, { IconProps } from '@ui/components/Icon/Icon';

export const CONTAINER_STYLE = { marginBottom: 50, marginRight: 10 };
export const AUTOCLOSE_DELAY = 5000;
export const DEFAULT_ZINDEX = 1001;
export const NOTIFICATIONS_LIMIT = 3;

export type SnackbarType = 'success' | 'error' | 'warning' | 'info';
export type Variant = 'outlined' | 'filled';

const COLOR_BY_TYPE = {
	success: 'green.6',
	error: 'red.6',
	warning: 'orange.6',
	info: 'gray.9'
} as const;

type ShowParams = { title?: string; information?: string }
type ShowWithDescriptionParams = ShowParams & { description?: string }

const ICON_FOR_TYPE: Record<SnackbarType, IconProps[ 'icon' ]> = {
	success: 'check',
	error: 'close',
	warning: 'warning',
	info: 'info'
};

// eslint-disable-next-line no-use-before-define
let sharedInstance: SnackbarSystem;

export default class SnackbarSystem {
	private notifications: typeof mantineNotifications;

	public static shared(): SnackbarSystem {
		if ( !sharedInstance ) {
			sharedInstance = new SnackbarSystem();
		}

		return sharedInstance;
	}

	constructor( notifications = mantineNotifications ) {
		this.notifications = notifications;

		this.showFilledSuccessMessage = this.showFilledSuccessMessage.bind( this );
		this.showFilledErrorMessage = this.showFilledErrorMessage.bind( this );
		this.showFilledWarningMessage = this.showFilledWarningMessage.bind( this );
		this.showFilledInfoMessage = this.showFilledInfoMessage.bind( this );
		this.showOutlinedSuccessMessage = this.showOutlinedSuccessMessage.bind( this );
		this.showOutlinedErrorMessage = this.showOutlinedErrorMessage.bind( this );
		this.showOutlinedWarningMessage = this.showOutlinedWarningMessage.bind( this );
		this.showOutlinedInfoMessage = this.showOutlinedInfoMessage.bind( this );
		this.showSuccessMessage = this.showSuccessMessage.bind( this );
		this.showErrorMessage = this.showErrorMessage.bind( this );
		this.showInfoMessage = this.showInfoMessage.bind( this );
		this.showLoadingMessage = this.showLoadingMessage.bind( this );
	}

	showFilledSuccessMessage( title: string ) {
		this.showMessage( { title, type: 'success', variant: 'filled' } );
	}

	showFilledErrorMessage( title: string ) {
		this.showMessage( { title, type: 'error', variant: 'filled' } );
	}

	showFilledWarningMessage( title: string ) {
		this.showMessage( { title, type: 'warning', variant: 'filled' } );
	}

	showFilledInfoMessage( title: string ) {
		this.showMessage( { title, type: 'info', variant: 'filled' } );
	}

	showOutlinedSuccessMessage( { title, information }: ShowParams ) {
		this.showMessage( { title, content: information, type: 'success', variant: 'outlined' } );
	}

	showOutlinedErrorMessage( { title, information }: ShowParams ) {
		this.showMessage( { title, content: information, type: 'error', variant: 'outlined' } );
	}

	showOutlinedWarningMessage( { title, information }: ShowParams ) {
		this.showMessage( { title, content: information, type: 'warning', variant: 'outlined' } );
	}

	showOutlinedInfoMessage( { title, information }: ShowParams ) {
		this.showMessage( { title, content: information, type: 'info', variant: 'outlined' } );
	}

	showSuccessMessage( { title, description }: ShowWithDescriptionParams ) {
		this.showMessage( { title, content: description, type: 'success', variant: 'outlined' } );
	}

	showErrorMessage( { title, description }: ShowWithDescriptionParams ) {
		this.showMessage( { title, content: description, type: 'error', variant: 'outlined' } );
	}

	showWarningMessage( { title, description }: ShowWithDescriptionParams ) {
		this.showMessage( { title, content: description, type: 'warning', variant: 'outlined' } );
	}

	showInfoMessage( { title, description }: ShowWithDescriptionParams ) {
		this.showMessage( { title, content: description, type: 'info', variant: 'outlined' } );
	}

	showMessage( {
		title, content, type, variant
	}: { title?: string, content?: string, type: SnackbarType, variant: Variant } ) {
		const variantProps = variant === 'outlined' ? {
			title,
			message: content,
			icon: <Icon icon={ICON_FOR_TYPE[ type ]} />
		} : {
			message: title
		};

		this.notifications.show( {
			...variantProps,
			variant,
			withBorder: true,
			color: COLOR_BY_TYPE[ type ]
		} );
	}

	showLoadingMessage( { id, title, description }: { id: string, title: string, description: string } ) {
		this.notifications.show( {
			id,
			title,
			message: description,
			color: 'primary',
			loading: true,
			autoClose: false,
			withCloseButton: false
		} );
	}

	hideLoadingMessage( id: string ) {
		this.notifications.hide( id );
	}

	hide() {
		this.notifications.clean();
	}
}
