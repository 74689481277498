import { ReactNode } from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ConnectedRouter } from 'connected-react-router';
import { Notifications } from '@mantine/notifications';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { MantineProvider } from '@mantine/core';
import {
	AUTOCLOSE_DELAY, CONTAINER_STYLE, DEFAULT_ZINDEX, NOTIFICATIONS_LIMIT
} from '@ui/systems/SnackbarSystem';

import { ApiProvider } from './ApiProvider';
import RootStoreProvider from './RootStoreProvider';
import PersistQueryClientProvider from './PersistQueryClientProvider';
import { persistor, store } from '../../store/store';
import history from '../../navigation/history';
import ReduxTanStackSyncProvider from './ReduxTanStackSyncProvider';
import theme from '../modules/ui/theme';

const AppProvider = ( { children }: { children: ReactNode } ) => (
	<RootStoreProvider>
		<ReduxProvider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<ConnectedRouter history={history}>
					<PersistQueryClientProvider>
						<ReduxTanStackSyncProvider>
							<MantineProvider
								withCSSVariables
								theme={theme}
							>
								<Notifications
									autoClose={AUTOCLOSE_DELAY}
									limit={NOTIFICATIONS_LIMIT}
									zIndex={DEFAULT_ZINDEX}
									style={CONTAINER_STYLE}
								/>
								<ApiProvider>
									{children}
								</ApiProvider>
							</MantineProvider>
						</ReduxTanStackSyncProvider>
						<ReactQueryDevtools initialIsOpen={false} />
					</PersistQueryClientProvider>
				</ConnectedRouter>
			</PersistGate>
		</ReduxProvider>
	</RootStoreProvider>
);

export default AppProvider;
