import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from '@ui/components/Icon/Icon';
import ProgressBar from '../../ProgressBar/ProgressBar';
import { PRIMARY_BASE } from '../../../styles/colors';

import './ProgressCardInfo.scss';

const ProgressCardInfo = ( {
	icon,
	title,
	valueCount,
	maxValue,
	unit,
	greyOut,
	maxedOutMultiplier,
	bytesFormat,
	hasAddOns
} ) => (
	<div className={classNames( 'ProgressCardInfo', { 'grey-out': greyOut } )}>
		<div className="icon">
			{icon}
		</div>
		<div className="usage-detail">
			<h5>{title}</h5>
			<h3 className={classNames( 'active-count', { hasAddOns } )}>
				<span className={classNames( 'counter' )}>
					Using {bytesFormat ? `${valueCount.formatBytes()}` : `${valueCount}`}
				</span>
				<span className="totalAmount"> of {bytesFormat ? maxValue.formatBytes() : maxValue} {unit}
				</span>
			</h3>
			<ProgressBar
				valueCount={valueCount}
				maxValue={maxValue}
				color={PRIMARY_BASE}
				maxedOutColor={PRIMARY_BASE}
				greyOut={greyOut}
				maxedOutMultiplier={maxedOutMultiplier}
			/>
		</div>
	</div>
);

ProgressCardInfo.propTypes = {
	icon: PropTypes.instanceOf( Icon ).isRequired,
	valueCount: PropTypes.number.isRequired,
	maxValue: PropTypes.number.isRequired,
	greyOut: PropTypes.bool.isRequired,
	title: PropTypes.string.isRequired,
	unit: PropTypes.string,
	maxedOutMultiplier: PropTypes.number,
	bytesFormat: PropTypes.bool,
	hasAddOns: PropTypes.bool
};

ProgressCardInfo.defaultProps = {
	unit: undefined,
	maxedOutMultiplier: 1,
	bytesFormat: false,
	hasAddOns: false
};

export default ProgressCardInfo;
