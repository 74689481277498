import { useCallback } from 'react';
import { InfiniteData, useInfiniteQuery, UseInfiniteQueryResult } from '@tanstack/react-query';
import Notification from '@notifications/models/Notification';
import NotificationFilter from '@notifications/models/NotificationFilter';
import useNotificationSystem from '@notifications/systems/useNotificationSystem';
import { NotificationsFetchResult } from '@notifications/systems/NotificationSystem';

import { QueryKeys } from './queryKeys';
import { DEFAULT_QUERY_OPTIONS } from './constants';

type UseFetchProjectNotificationsQueryParams = { projectID: number, selectedFilter: NotificationFilter };

const asFlatList = ( data: InfiniteData<NotificationsFetchResult> ) => (
	data.pages.flatMap( page => Notification.allFromJson( page.notifications ) )
);

const paginationParams = ( pageParam?: { page: number, older_than: string } ) => (
	{
		page: pageParam?.page || 1,
		olderThan: pageParam?.older_than ? new Date( pageParam?.older_than ) : new Date()
	}
);

const useFetchProjectNotificationsQuery = ( {
	projectID, selectedFilter
}: UseFetchProjectNotificationsQueryParams ) => {
	const system = useNotificationSystem();
	const select = useCallback( ( data: InfiniteData<NotificationsFetchResult> ) => (
		asFlatList( data ).filter( notification => selectedFilter.isPassedBy( notification ) )
	), [ selectedFilter ] );

	return useInfiniteQuery(
		QueryKeys.forProject( { projectID, filter: selectedFilter.backendId } ),
		( { pageParam } ) => system.fetchProjectNotifications( {
			projectID,
			...paginationParams( pageParam ),
			...( selectedFilter.isBackendFilter
				? { filter: selectedFilter.backendId! }
				: {}
			)
		} ),
		{
			getNextPageParam: ( { current_page, total_pages, older_than } ) => (
				current_page >= total_pages ? undefined : ( {
					page: current_page + 1,
					older_than
				} )
			),
			// TODO: fix this typing error when migrating to Tanstack Query v5
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			select,
			...DEFAULT_QUERY_OPTIONS
		}
	) as Omit<UseInfiniteQueryResult, 'data'> & { data?: Notification[] };
}

export default useFetchProjectNotificationsQuery;
