/* eslint-disable max-len */
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from '@ui/components/Icon/Icon';

import WorkspaceBillingInformation from '../../../entities/workspaceBillingInformation';

import './BillingInformation.scss';

const BillingInformation = ( { billingInformation } ) => {
	const empty = !billingInformation || billingInformation.hasAllEmptyValues;

	const content = empty
		? <p>No billing information</p>
		: (
			<div className="billing-information-body">
				<div className="title">
					<Icon className="business-icon" icon="business" />
					<h4>{billingInformation.companyName}</h4>
				</div>
				<p>{billingInformation.address}, {billingInformation.state} {billingInformation.postalCode} </p>
				<p>{billingInformation.countryName}</p>
			</div>
		);

	return (
		<div className={classNames( 'BillingInformation', { empty } )}>
			{content}
		</div>
	);
};

BillingInformation.propTypes = {
	billingInformation: PropTypes.instanceOf( WorkspaceBillingInformation )
};

BillingInformation.defaultProps = {
	billingInformation: undefined
};

export default BillingInformation;
