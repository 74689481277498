import MatchedNewRoundsConfirmationPopup
	from '@uploads/components/MatchedNewRoundsConfirmationPopup/MatchedNewRoundsConfirmationPopup';
import { textEndEllipsis } from '../lib/textTruncate';
import { DELETE_ASSET_CONFIRMATION, DELETE_CURRENT_ROUND_CONFIRMATION, DELETE_CURRENT_ROUND_TITLE } from '../messages/assets';
import ContactsGroupsModal from '../modals/ContactsGroupsModal/ContactsGroupsModal';
import IncompleteFolderUploadPopup from '../modals/IncompleteFolderUploadPopup/IncompleteFolderUploadPopup';
import LabelsGroupsModal from '../modals/LabelsGroupsModal/LabelsGroupsModal';
import UnsupportedFileFormatsPopup from '../modals/UnsupportedFileFormatsPopup/UnsupportedFileFormatsPopup';
import OnboardingSurveyPopup from '../modals/OnboardingSurveyPopup/OnboardingSurveyPopup';

export default class PopupFactory {
	static deleteAssetConfirmationPopup = popupSystem => this.deleteConfirmationPopup( {
		popupSystem, title: 'Delete Asset', description: DELETE_ASSET_CONFIRMATION
	} )

	static deleteAssetsConfirmationPopup = popupSystem => this.deleteConfirmationPopup( {
		popupSystem, title: 'Are you sure you want to delete these assets for all members? You won\'t be able to recover them.'
	} )

	static deleteItemsConfirmationPopup = ( { itemsCount, popupSystem } ) => this.deleteConfirmationPopup( {
		popupSystem, title: `Delete ${'item'.pluralize( itemsCount )}`, description: `Are you sure you want to delete ${itemsCount} ${'item'.pluralize( itemsCount )} for all members?\nYou won't be able to recover ${itemsCount === 1 ? 'it' : 'them'}.`
	} )

	static deleteCurrentRoundConfirmationPopup = popupSystem => this.deleteConfirmationPopup( {
		popupSystem, title: DELETE_CURRENT_ROUND_TITLE, description: DELETE_CURRENT_ROUND_CONFIRMATION
	} )

	static storageLimitReachedPopup = ( { popupSystem, workspace } ) => popupSystem.showErrorDialog( {
		title: 'Storage limit reached!',
		description: `This upload exceeds ${workspace.currentUserIsOwner ? 'your workspace' : workspace.name}'s storage limit of ${workspace.includedStorage.formatBytes()}. ${( workspace.isOnFreeTrial && workspace.currentUserIsOwner )
			? 'In order to proceed, please upgrade to a paid workspace.'
			: ''}${workspace.currentUserIsOwner
			? ''
			: '\n\nMake space by deleting files you no longer need, or contact your Workspace Owner to request more storage.'}`,
		acceptText: workspace.currentUserIsOwner ? 'Upgrade' : workspace.owner.email,
		buttonType: workspace.currentUserIsOwner ? 'primary' : 'link'
	} );

	static expiredFreeTrialPopup = ( { popupSystem, isMobile } ) => {
		const DESCRIPTION_PREFIX = 'Since your free trial expired, your workspace is suspended.\n\n';
		const MOBILE_DESCRIPTION_SUFFIX = 'Please subscribe to a plan in desktop.';
		const DESKTOP_DESCRIPTION_SUFFIX = 'You can still download the latest round of assets, hide it from your active projects or transfer it to a paid workspace.';

		return popupSystem.showErrorDialog( {
			title: 'Free Trial Expired',
			description: `${DESCRIPTION_PREFIX}${isMobile ? MOBILE_DESCRIPTION_SUFFIX : DESKTOP_DESCRIPTION_SUFFIX}`,
			acceptText: isMobile ? 'Ok' : 'See Plans',
			buttonType: 'primary'
		} );
	};

	static suspendedWorkspacePopup = popupSystem => popupSystem.showErrorDialog( {
		title: 'Suspended Workspace',
		description: 'The workspace that owns this project has been suspended.\n\nPlease subscribe to a plan in desktop.',
		acceptText: 'Ok',
		buttonType: 'primary'
	} )

	static enable2FAConfiramtionPopup = ( {
		popupSystem, email
	} ) => popupSystem.showDefaultConfirmationDialog( {
		title: 'Activate two step verification',
		description: `Email verification factor will be turned on with\n${email}\n\nMake sure this address is correct, you will need to have access to it every time you log in.`,
		acceptText: 'Activate',
		descriptionAlignment: 'center'
	} ).then( ( confirm ) => {
		popupSystem.hideConfirmationDialog();
		return confirm;
	} )

	static deleteConfirmationPopup = ( {
		popupSystem, title, description
	} ) => popupSystem.showDangerConfirmationDialog( {
		title,
		description,
		descriptionAlignment: 'center',
		acceptText: 'Delete',
		cancelText: 'Cancel'
	} ).then( ( confirm ) => {
		popupSystem.hideConfirmationDialog();
		return confirm;
	} )

	static buyStorageAddOnConfirmationPopup = ( {
		popupSystem, description
	} ) => popupSystem
		.showDefaultConfirmationDialog( {
			title: 'Confirm Additional Storage',
			description,
			acceptText: 'Confirm',
			cancelText: 'Cancel',
			descriptionAlignment: 'center'
		} ).then( ( confirm ) => {
			popupSystem.hideConfirmationDialog();
			return confirm;
		} );

	static buyMembersAddOnConfirmationPopup = ( {
		popupSystem, description
	} ) => popupSystem
		.showDefaultConfirmationDialog( {
			title: 'Confirm Additional Workspace Seat',
			description,
			acceptText: 'Confirm',
			cancelText: 'Cancel',
			descriptionAlignment: 'center'
		} ).then( ( confirm ) => {
			popupSystem.hideConfirmationDialog();
			return confirm;
		} );

	static removeStorageAddOnConfirmationPopup = ( {
		popupSystem, description
	} ) => popupSystem
		.showDangerConfirmationDialog( {
			title: 'Remove Additional Storage',
			description,
			checkboxDescription: 'I understand that by removing it I will lose this extra storage with no chance to use or recover it unless I pay for it again.',
			acceptText: 'Remove',
			cancelText: 'Cancel',
			descriptionAlignment: 'center'
		} ).then( ( confirm ) => {
			popupSystem.hideConfirmationDialog();
			return confirm;
		} );

	static removeMembersAddOnConfirmationPopup = ( {
		popupSystem, description
	} ) => popupSystem
		.showDangerConfirmationDialog( {
			title: 'Remove 1 Workspace Seat',
			description,
			checkboxDescription: 'I understand that by removing a workspace seat I will lose it with no chance to use or recover it unless I pay for it again.',
			acceptText: 'Remove',
			cancelText: 'Cancel',
			descriptionAlignment: 'center'
		} ).then( ( confirm ) => {
			popupSystem.hideConfirmationDialog();
			return confirm;
		} );

	static removeStorageAddOnErrorPopup = ( {
		popupSystem
	} ) => popupSystem
		.showDialogWithIcon( {
			title: 'Storage in use',
			description: 'In order to remove this additional storage you first have to free up the space that is in use with it. You can delete files or assets that you do not need.',
			acceptText: 'Manage Storage',
			isError: true,
			descriptionAlignment: 'center'
		} ).then( ( confirm ) => {
			popupSystem.hideConfirmationDialog();
			return confirm;
		} );

	static removeMembersAddOnErrorPopup = ( {
		popupSystem
	} ) => popupSystem
		.showDialogWithIcon( {
			title: 'Workspace seat in use',
			description: 'All of your seats are currently in use. In order to remove a seat from your plan, please first remove a workspace member.',
			acceptText: 'Manage Seats',
			isError: true,
			descriptionAlignment: 'center'
		} ).then( ( confirm ) => {
			popupSystem.hideConfirmationDialog();
			return confirm;
		} );

	static leaveProjectConfirmationPopup = ( {
		popupSystem, isLeavingMultiple
	} ) => popupSystem.showDefaultConfirmationDialog( {
		title: `Leave Project${isLeavingMultiple ? 's' : ''}`,
		description: `Are you sure you want to leave ${isLeavingMultiple ? 'these' : 'this'} project${isLeavingMultiple ? 's' : ''}?`,
		acceptText: 'Yes, leave',
		cancelText: 'Cancel',
		descriptionAlignment: 'center'
	} ).then( ( confirm ) => {
		popupSystem.hideConfirmationDialog();
		return confirm;
	} )

	static deleteProjectsConfirmationPopup = ( {
		popupSystem, isDeletingMultiple
	} ) => popupSystem.showDangerConfirmationDialog( {
		title: `Delete Project${isDeletingMultiple ? 's' : ''}`,
		description: `Are you sure you want to delete project${isDeletingMultiple ? 's' : ''}\nfor all members? You won't be able to recover ${isDeletingMultiple ? 'them' : 'it'}.`,
		checkboxDescription: `I understand that I will lose all content and information related to ${isDeletingMultiple ? 'these projects' : 'this project'}.`,
		acceptText: 'Yes, delete',
		cancelText: 'No',
		descriptionAlignment: 'center'
	} ).then( ( confirm ) => {
		popupSystem.hideConfirmationDialog();
		return confirm;
	} );

	static downgradeSubscriptionConfirmationPopup = popupSystem => popupSystem
		.showDefaultConfirmationDialog( {
			title: 'Before You Continue',
			description: 'Downgrades don’t become effective automatically.\n\nAt the end of your current billing period your subscription won’t be renewed and you will then be able to sign up for a Pro Plan.\n\nDo you want to stop your subscription’s renewal?',
			acceptText: 'Stop Renewal',
			descriptionAlignment: 'center'
		} ).then( ( confirm ) => {
			popupSystem.hideConfirmationDialog();
			return confirm;
		} )

	static workspaceSuspendedPopup = popupSystem => popupSystem
		.showDialogWithIcon( {
			title: 'Workspace Suspended',
			description: 'Since you wished to downgrade, your subscription was not renewed and your workspace has been suspended.',
			acceptText: 'See More ',
			buttonType: 'primary',
			descriptionAlignment: 'center'
		} ).then( ( confirm ) => {
			popupSystem.hideConfirmationDialog();
			return confirm;
		} );

	static unfoldItems = ( { assetCount, folderCount, folder, project, popupSystem } ) => {
		const itemCount = assetCount + folderCount;
		const folderHasAncestors = folder.ancestors?.length > 0 ? '/...' : '';

		return popupSystem
			.showDefaultConfirmationDialog( {
				title: `Move ${'item'.pluralize( itemCount )}`,
				description: `You are about to move ${itemCount} ${'item'.pluralize( itemCount )} from ${textEndEllipsis( project.name, 50 )} ${folderHasAncestors}/ *startBold*${textEndEllipsis( folder.name, 50 )}*endBold* to *startBold*${textEndEllipsis( project.name, 50 )}*endBold*`,
				acceptText: 'Confirm',
				cancelText: 'Cancel',
				buttonType: 'primary',
				descriptionAlignment: 'center'
			} ).then( ( confirm ) => {
				popupSystem.hideConfirmationDialog();
				return confirm;
			} );
	}

	static cannotUnfoldItemsWithDuplicateNames = popupSystem => popupSystem
		.showDialogWithWarningIcon( {
			title: 'Duplicate Name Issue',
			description: 'We weren’t able to fully complete the transfer. One or more items with the same name already exist in that location.\n\nTo proceed, please *startBold*rename and try again*endBold*.',
			acceptText: 'Got it',
			descriptionAlignment: 'center'
		} );

	static deleteCommentConfirmationPopup = ( { popupSystem, comment } ) => popupSystem
		.showDefaultConfirmationDialog( {
			title: comment.deleteConfirmationTitle,
			description: comment.deleteConfirmationText,
			acceptText: 'Delete',
			cancelText: 'Cancel',
			descriptionAlignment: 'center',
			acceptButtonColor: 'danger'
		} );

	static projectLimitReached = popupSystem => popupSystem
		.showDialogWithIcon( {
			title: "Seems you've reached\nyour projects limit",
			description: 'You can upgrade to have unlimited projects.\n(Also much more storage!)',
			acceptText: 'Upgrade',
			isError: true
		} );

	static cannotCreateProjectWithoutWorkspace = popupSystem => popupSystem.showDialog( {
		title: 'Create a Workspace',
		description: 'You cannot create a project in any workspace.\nCreate one to start collaborating.',
		acceptText: 'Create Workspace'
	} )

	static cannotCreateProjectWithSuspendedWorkspace = popupSystem => popupSystem
		.showDialogWithIcon( {
			title: 'Workspace Suspended',
			description: 'Since your workspace is suspended,\nyou can’t create a project.\n\nPurchase a subscription to continue.',
			acceptText: 'See Plans',
			descriptionAlignment: 'center',
			acceptButtonColor: 'danger'
		} );

	static cannotCreateProjectWithExpiredFreeTrial = popupSystem => popupSystem
		.showDialogWithIcon( {
			title: 'Free Trial Expired',
			description: 'Since your free trial expired, you can’t create a project. \nPurchase a subscription to reactivate.',
			acceptText: 'See Plans',
			descriptionAlignment: 'center',
			acceptButtonColor: 'danger'
		} );

	static assetNotFoundPopup = popupSystem => popupSystem
		.showDialog( {
			title: 'Asset Not Found',
			description: 'Seems like someone from the project has deleted this asset.',
			acceptText: 'Got it'
		} );

	static defaultErrorDialog = popupSystem => popupSystem.showErrorDialog( {
		title: 'Oops, something went wrong!',
		description: 'Please try again later.'
	} );

	static unsupportedFileFormatsPopup = ( {
		popupSystem, unsupportedFormats
	} ) => popupSystem.showCustomPopup( {
		renderPopup: ( { onConfirm } ) => (
			<UnsupportedFileFormatsPopup
				unsupportedFormats={unsupportedFormats}
				onConfirm={onConfirm}
			/>
		)
	} );

	static onboardingSurveyPopup = ( {
		popupSystem, snackbarSystem, user
	} ) => popupSystem.showCustomPopup( {
		renderPopup: ( { onConfirm } ) => (
			<OnboardingSurveyPopup
				user={user}
				snackbarSystem={snackbarSystem}
				onClose={onConfirm}
			/>
		)
	} );

	static duplicateFolderNamesPopup = ( { popupSystem } ) => popupSystem.showDialog( {
		title: 'Duplicate Folder Names',
		description: 'Sorry, we couldn\'t complete the upload.\nOne or more folders with the same name already exist in that location. *startBold*Please rename and try again*endBold*',
		acceptText: 'Got it'
	} );

	static incompleteFolderUploadPopup = ( {
		popupSystem, failedUploads
	} ) => popupSystem.showCustomPopup( {
		renderPopup: ( { onConfirm, onCancel } ) => (
			<IncompleteFolderUploadPopup
				failedUploads={failedUploads}
				onConfirm={onConfirm}
				onCancel={onCancel}
			/>
		)
	} );

	static deleteContactGroupConfirmationPopup = popupSystem => popupSystem.showDangerConfirmationDialog( {
		title: 'Delete Collaborator Group',
		description: 'Are you sure you want to delete this group?\n*startBold*These collaborators won&apos;t be deleted from any of the projects to which they were previously invited.*endBold*',
		acceptText: 'Delete',
		cancelText: 'Cancel',
		descriptionAlignment: 'center'
	} ).then( ( confirm ) => {
		popupSystem.hideConfirmationDialog();
		return confirm;
	} );

	static labelGroupsPopup = ( { popupSystem, onClose, projectID } ) => popupSystem.showCustomPopup( {
		renderPopup: ( { onConfirm, onCancel } ) => (
			<LabelsGroupsModal
				projectID={projectID}
				onClose={() => { onClose?.(); onCancel() }}
				onConfirm={() => { onClose?.(); onConfirm() }}
			/>
		)
	} );

	static contactGroupsPopup = ( { popupSystem, onClose, projectID } ) => popupSystem.showCustomPopup( {
		renderPopup: ( { onConfirm, onCancel } ) => (
			<ContactsGroupsModal
				projectID={projectID}
				onClose={() => { onClose?.(); onCancel() }}
				onConfirm={() => { onClose?.(); onConfirm() }}
			/>
		)
	} );

	static deleteLabelGroupConfirmationPopup = popupSystem => popupSystem.showDangerConfirmationDialog( {
		title: 'Delete Label Group',
		description: 'Are you sure you want to delete this group?\n*startBold*These labels won&apos;t be deleted from any of the projects to which they were previously added.*endBold*',
		acceptText: 'Delete',
		cancelText: 'Cancel',
		descriptionAlignment: 'center'
	} ).then( ( confirm ) => {
		popupSystem.hideConfirmationDialog();
		return confirm;
	} );

	static deleteAssetsLastRoundConfirmationPopup = ( popupSystem, assetsCount ) => popupSystem
		.showDangerConfirmationDialog( {
			title: 'Delete Last Round',
			description: `Are you sure you want to delete the last round of ${assetsCount > 1 ? 'these assets' : 'this asset'} for all members?`,
			acceptText: 'Delete',
			cancelText: 'Cancel',
			descriptionAlignment: 'center'
		} ).then( ( confirm ) => {
			popupSystem.hideConfirmationDialog();
			return confirm;
		} );

	static deleteAssetsLastRoundErrorPopup = popupSystem => popupSystem
		.showDialog( {
			title: 'We could not complete the action because of the following:',
			description: 'We can\'t delete rounds that have *startBold*comments*endBold* or haven\'t been *startBold*uploaded*endBold* by you. Make sure that all the rounds you want to delete meet these criteria',
			acceptText: 'Got it',
			descriptionAlignment: 'center'
		} );

	static matchedAssets = ( { popupSystem, matchResult } ) => popupSystem.showCustomPopupReturningValue( {
		renderPopup: ( { onConfirm, onCancel } ) => (
			<MatchedNewRoundsConfirmationPopup
				matchResult={matchResult}
				onConfirm={onConfirm}
				onCancel={onCancel}
			/>
		)
	} );

	static insufficientStorageForMovedItemsPopup = ( { workspaceData, popupSystem } ) => {
		const { name, ownerEmail } = workspaceData;

		return popupSystem.showErrorDialog( {
			title: 'Not enough workspace storage',
			description: `There's not enough available storage on the workspace ${name} to complete the move. Contact owner ${ownerEmail}.`,
			acceptText: 'Got it',
			buttonType: 'primary'
		} );
	}
}
