class QueryKeys {
	static forProjectTree() {
		return [ 'projects', 'tree' ];
	}

	static forProjectMembers( { projectID }: { projectID: number } ) {
		return [ 'projects', projectID, 'members' ];
	}

	static forIncludedFeatures( projectID: number ) {
		return [ 'projects', projectID, 'included_features' ];
	}
}

export { QueryKeys };
