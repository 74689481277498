import { useState } from 'react';
import PropTypes from 'prop-types';
import Icon from '@ui/components/Icon/Icon';

import CloseableModal from '../CloseableModal';
import IconButton from '../../components/UI/IconButton/IconButton';
import Select from '../../components/UI/Select/Select';
import TextArea from '../../components/UI/TextArea/TextArea';
import Button from '../../new_arch/components/Button/Button';
import WorkspaceMember from '../../entities/workspaceMember';
import { textEndEllipsis } from '../../lib/textTruncate';

import './TransferWorkspaceOwnershipModal.scss';

const TransferWorkspaceOwnershipModal = ( {
	onClose, workspaceName, members, onOwnershipTransfer, disableButton
} ) => {
	const getMemberOptions = () => members.map(
		member => ( { label: member.user.name, value: member, greyOut: member.userOwnsAWorkspace } )
	);
	const [ message, setMessage ] = useState( '' );
	const [ selectedMember, setSelectedMember ] = useState( undefined );

	const onSubmit = () => {
		if ( !selectedMember ) { return; }
		onOwnershipTransfer( selectedMember.value, message );
	};

	const selectionIsValid = !!selectedMember && !selectedMember.value.userOwnsAWorkspace;
	const selectionIsError = !selectionIsValid && selectedMember;

	return (
		<CloseableModal onClose={onClose} name="transfer-workspace">
			<div className="TransferWorkspaceOwnershipModal">
				<div className="header">
					<h4>Transfer Workspace Ownership</h4>
					<IconButton icon="close" onClick={onClose} />
				</div>
				<div className="body">
					<p>
						You are currently the owner of <strong>{textEndEllipsis( workspaceName )}</strong> workspace. In order{' '}
						to transfer the ownership, please select a new owner below.<br />
						Keep in mind:
					</p>
					<div className="warning-item">
						<Icon icon="check" />
						<p>You will no longer have access to billing.</p>
					</div>
					<div className="warning-item">
						<Icon icon="check" />
						<p>{'You\'ll still be able to collaborate and create new'}<br />projects for the workspace.</p>
					</div>
					<div className="warning-item">
						<Icon icon="check" />
						<p>
							{'You can only transfer the ownership to a Member that doesn\'t own a workspace. (They can delete it if they own one).'}
						</p>
					</div>
					<Select
						type="tall"
						label="New Workspace Owner"
						options={getMemberOptions()}
						value={selectedMember}
						onChange={setSelectedMember}
						placeholder="Select a New Owner..."
						invalidSelection={!selectionIsValid}
						error={selectionIsError && 'This user already owns a workspace.'}
						willShowError
					/>
					<TextArea
						rows={3}
						label="Include a Message"
						maxLength={300}
						onChange={setMessage}
						value={message}
						placeholder="Write your message here"
					/>
					<div className="button-container">
						<Button
							onClick={onSubmit}
							disabled={!selectionIsValid || disableButton}
						>
							Transfer
						</Button>
					</div>
				</div>
			</div>
		</CloseableModal>
	);
};

TransferWorkspaceOwnershipModal.propTypes = {
	onClose: PropTypes.func.isRequired,
	workspaceName: PropTypes.string.isRequired,
	members: PropTypes.arrayOf( WorkspaceMember ),
	onOwnershipTransfer: PropTypes.func,
	disableButton: PropTypes.bool
};

TransferWorkspaceOwnershipModal.defaultProps = {
	members: [],
	onOwnershipTransfer: () => {},
	disableButton: false
};

export default TransferWorkspaceOwnershipModal;
