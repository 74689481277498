import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import SnackbarSystem from '@ui/systems/SnackbarSystem';

import { getSortedWorkspaceProjects, getWorkspace } from '../../selectors/workspaces';
import { connectComponent } from '../../lib/connectComponent';
import WorkspaceProjectList from '../../components/WoorkspaceProjectList/WorkspaceProjectList';
import Container from '../Container';
import { trackNavigatedToWorkspaceProjects } from '../../services/tracker/trackers/navigation';
import Workspace from '../../entities/workspace';
import { getSelectedProjectsSortOption, getSortAscending } from '../../selectors/projectsViewOptions';
import SortOption from '../../entities/sortOption';
import InviteProjectMemberBoxModal from '../../modals/InviteProjectMemberBoxModal/InviteProjectMemberBoxModal';
import ShareReviewLinkModal from '../../modals/ShareReviewLinkModal/ShareReviewLinkModal';
import AsyncActionPresenterFactory from '../../factories/AsyncActionPresenterFactory';
import PopupSystem from '../../systems/PopupSystem';
import CreateProjectModal from '../../modals/CreateProejectModal/CreateProjectModal';
import HistoryPropType from '../../types/history';
import PricingSubscriptionSelectionModal from '../../modals/PricingSubscriptionSelectionModal/PricingSubscriptionSelectionModal';
import PricingSubscriptionPresenter from '../../presenters/PlanSettings/PricingSubscriptionPresenter';
import WorkspaceSubscriptionCheckoutModalContainer from '../WorkspaceSubscriptionCheckoutModalContainer/WorkspaceSubscriptionCheckoutModalContainer';
import PopupFactory from '../../factories/PopupFactory';
import CreateProjectPresenter from '../../presenters/CreateProjectPresenter/CreateProjectPresenter';

export class WorkspaceProjectListContainer extends Container {
	constructor() {
		super();

		this._defaultState = { OpenedModal: () => null, openedModalProjectID: null };
		this.state = this._defaultState;
	}

	componentDidMount() {
		trackNavigatedToWorkspaceProjects();
	}

	dropdownItemsForProject = project => [
		{
			name: 'Invite Collaborators',
			triggerAction: clickedProject => this._openModal( {
				Component: InviteProjectMemberBoxModal,
				props: {
					projectID: clickedProject.id,
					fetchProjectMembers: () => this._fetchProjectMembers( clickedProject )
				}
			} ),
			condition: project.currentUserIsPart
		},
		{
			name: 'Share Review Link',
			triggerAction: clickedProject => this._openModal( {
				Component: ShareReviewLinkModal,
				props: {
					shareable: clickedProject
				}
			} ),
			condition: project.currentUserIsPart
		},
		{
			name: 'Delete',
			triggerAction: this._deleteProjectWasClicked,
			condition: this._currentUserCanDeleteProject( project )
		},
		{
			name: 'Leave Project',
			triggerAction: this._leaveProjectWasClicked,
			condition: !this._currentUserCanDeleteProject( project )
		}
	].filter( item => item.condition )

	_currentUserCanDeleteProject = project => (
		this.props.workspace.currentUserIsOwner || project.currentUserIsOwner
	);

	_deleteWorkspaceProject( projectID ) {
		const { deleteProjects } = this.props;
		this.handleAsyncActionResult(
			deleteProjects( [ projectID ] ),
			{ title: 'The project was successfully deleted.' },
			undefined
		);
	}

	_fetchProjectMembers( project ) {
		this.props.fetchProjectMembers( project.id );
	}

	get _userCanCreateProjects() {
		return this.props.workspace.hasRoomForProjects && this.props.workspace.isActive;
	}

	get _workspaceID() {
		return this.props.workspace.id;
	}

	_redirectToNewProject( createdProjectID ) {
		this.props.history.replace( `/projects/${createdProjectID}` );
	}

	_openSubscriptionSelectionModal = () => {
		this._openModal( {
			Component: PricingSubscriptionSelectionModal,
			props: {
				workspaceID: this._workspaceID,
				presenter: new PricingSubscriptionPresenter( { workspace: this.props.workspace } ),
				onClose: this._closeModal,
				onUpgradeButtonClicked: this._openCheckoutModal
			}
		} );
	}

	_openCheckoutModal = ( pricingPlanPrice ) => {
		this._openModal( {
			Component: WorkspaceSubscriptionCheckoutModalContainer,
			props: {
				workspaceID: this._workspaceID,
				onClose: this._closeModal,
				onSuccessfulDialogAccepted: this._closeModal,
				pricingPlanPrice
			}
		} );
	}

	_onProjectCreationSuccess = ( createdProjectID ) => {
		this._closeModal();
		this._redirectToNewProject( createdProjectID );
	}

	_handleUserCannotCreateProject = () => PopupFactory.projectLimitReached( this.props.popupSystem )
		.then( accept => accept && this._openSubscriptionSelectionModal() )

	_openCreateProjectModal = () => (
		this._openModal( {
			Component: CreateProjectModal,
			props: {
				onProjectCreationSuccess: this._onProjectCreationSuccess,
				onProjectCreationFailed: this._handleUserCannotCreateProject,
				initialWorkspaceID: this._workspaceID
			}
		} )
	)

	_onAddProjectButtonClick = () => {
		new CreateProjectPresenter( {
			popupSystem: this.props.popupSystem,
			workspace: this.props.workspace,
			canCreateProjects: this._userCanCreateProjects,
			onErrorAccept: this._openSubscriptionSelectionModal,
			startCreateProjectFlow: this._openCreateProjectModal
		} ).createProject();
	}

	_leaveProjectWasClicked = ( project ) => {
		AsyncActionPresenterFactory.forLeaveProjects( {
			leaveProjects: this.props.leaveProjects,
			projectsIDs: [ project.id ],
			leftProjectName: project.name,
			popupSystem: this.props.popupSystem,
			snackbarSystem: this.props.snackbarSystem
		} ).trigger();
	}

	_deleteProjectWasClicked = ( project ) => {
		const { popupSystem } = this.props;
		popupSystem.showDangerConfirmationDialog( {
			title: 'Delete Project',
			description: `Are you sure you want to delete ${project.name} project\nfor all members? You won't be able to recover it.`,
			checkboxDescription: 'I understand that I will lose all content and information related to this project',
			acceptText: 'Yes, delete',
			cancelText: 'Cancel',
			descriptionAlignment: 'left'
		} )
			.then( ( confirm ) => {
				if ( confirm ) this._deleteWorkspaceProject( project.id );
				popupSystem.hideConfirmationDialog();
			} );
	}

	_dropdownItemWasClicked = ( { index, project } ) => {
		this.dropdownItemsForProject( project )[ index ].triggerAction( project );
	}

	_openModal( { Component, props } ) {
		this.setState( { OpenedModal: Component, openedModalProps: props } );
	}

	_closeModal = () => {
		this.setState( this._defaultState );
	}

	_renderModal() {
		const { OpenedModal, openedModalProps } = this.state;
		return <OpenedModal {...openedModalProps} onClose={this._closeModal} />;
	}

	render() {
		const {
			projects, workspace, selectedSortOption, setProjectsViewSort, ascending
		} = this.props;
		return (
			<>
				{this._renderModal()}
				<WorkspaceProjectList
					projects={projects}
					workspace={workspace}
					selectedSortOption={selectedSortOption}
					setProjectsViewSort={setProjectsViewSort}
					ascending={ascending}
					dropdownItemsForProject={this.dropdownItemsForProject}
					onDropdownItemClick={this._dropdownItemWasClicked}
					onAddProjectButtonClick={this._onAddProjectButtonClick}
					onPaidFeatureModalAcceptClick={this._openSubscriptionSelectionModal}
				/>
			</>
		);
	}
}

WorkspaceProjectListContainer.propTypes = {
	workspaceID: PropTypes.number.isRequired,
	projects: PropTypes.arrayOf( PropTypes.shape( {
		id: PropTypes.number,
		name: PropTypes.string.isRequired,
		coverUrl: PropTypes.string,
		membersCount: PropTypes.number.isRequired,
		owner: PropTypes.shape( { name: PropTypes.string.isRequired } ),
		users: PropTypes.arrayOf( PropTypes.number ),
		createdOn: PropTypes.instanceOf( Date )
	} ) ),
	popupSystem: PropTypes.instanceOf( PopupSystem ).isRequired,
	snackbarSystem: PropTypes.instanceOf( SnackbarSystem ).isRequired,
	deleteProjects: PropTypes.func,
	workspace: PropTypes.instanceOf( Workspace ),
	selectedSortOption: PropTypes.instanceOf( SortOption ),
	setProjectsViewSort: PropTypes.func,
	ascending: PropTypes.bool,
	history: HistoryPropType.isRequired
};

WorkspaceProjectListContainer.defaultProps = {
	projects: [],
	deleteProjects: () => {},
	workspace: undefined,
	selectedSortOption: undefined,
	setProjectsViewSort: () => {},
	ascending: true
};

export default withRouter( connectComponent( ( state, props ) => ( {
	workspace: getWorkspace( state, props ),
	projects: getSortedWorkspaceProjects( state, props ),
	selectedSortOption: getSelectedProjectsSortOption( state ),
	ascending: getSortAscending( state )
} ) )( WorkspaceProjectListContainer ) );
