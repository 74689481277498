import { memo, useCallback } from 'react';
import { Button, Group, Stack } from '@mantine/core';
import classNames from 'classnames';
import Icon from '@ui/components/Icon/Icon';
import { BLACK } from 'src/styles/colors';
import useNavigate from 'src/new_arch/navigation/useNavigate';
import FilterSelector from 'src/components/FilterSelector/FilterSelector';
import IconButton from 'src/components/UI/IconButton/IconButton';
import { trackNotificationSettingsOpenedFromPanel } from 'src/services/tracker/trackers/notifications';

import NotificationFilter from '@notifications/models/NotificationFilter';

import './NotificationsHeader.scss';

type NotificationsHeaderProps = {
	selectedFilter: NotificationFilter
	onFilterChange: ( filter: NotificationFilter ) => void,
	onClose?: () => void
};

const NotificationsHeader = ( {
	selectedFilter, onFilterChange, onClose
}: NotificationsHeaderProps ) => {
	const navigate = useNavigate();

	const onSettingsButtonClicked = useCallback( () => {
		trackNotificationSettingsOpenedFromPanel();
		navigate.toProfileNotifications();
	}, [ navigate ] );

	const noFilterSelected = selectedFilter.id === NotificationFilter.none().id;

	return (
		<Stack
			className={classNames( 'NotificationsHeader', { 'no-filter-selected': noFilterSelected } )}
			spacing={9}
		>
			<Group className="header" spacing={12} position="apart">
				<h4>Notifications</h4>
				<IconButton onClick={onClose} icon="close" color={BLACK} size={16} />
			</Group>
			<Group className="filter" spacing={12} position="apart">
				<FilterSelector
					selectedFilterOption={selectedFilter}
					filterOptions={NotificationFilter.allOptions()}
					onViewFilterSet={onFilterChange}
					isSearchable
				/>
				<Button
					className="settings-button"
					leftIcon={<Icon icon="settings_outlined" color={BLACK} size={14} />}
					size="xs"
					variant="default"
					onClick={onSettingsButtonClicked}
				>
					Settings
				</Button>
			</Group>
		</Stack>
	);
};

export default memo( NotificationsHeader );
