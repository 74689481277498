import { ComponentType, useCallback, useEffect, useRef } from 'react'
import { VariableSizeList as ReactWindowList } from 'react-window';
import useMarginBottomOnElement from '../../../hooks/useMarginBottomOnElement';
import { ListSizeProps, VariableSizeListProps } from './types';
import ListRow, { ListRowProps } from './ListRow';
import useItemKey from './useItemKey';
import useListContext from './useListContext';

/*
TODO: refactor this component to make it support the 'gap' prop just like Grid does.

A component for rendering a list of arbitrary items.

Keep in mind:
- It is virtualized (with react-window).
- It grows vertically.
- Exact row height must be provided.
- It automatically covers the entire width and height it has available.
- The `List` itself does not render any element for the rows; you must do
it in the `renderItem` callback applying the `cssStyle` received.
- If the components you render inside the rows are expensive, it is
recommended to memoize them, since the `renderItem` callbacks are called
constantly while scrolling.
*/
const VariableSizeList = <TItem, >( {
	items,
	renderItem,
	itemKeyExtractor,
	loaderCount,
	renderLoader,
	width,
	height,
	estimatedRowHeight,
	itemHeight,
	overscanRowCount,
	onScrollEndReached,
	bottomEmptySpace = 0,
	scrollableContainerRef
}: VariableSizeListProps<TItem> & ListSizeProps ) => {
	const ref = useRef<ReactWindowList | null>( null );
	const rowCount = Math.ceil( items.length + loaderCount );
	const fullListHeight = estimatedRowHeight * rowCount;

	const listContext = useListContext<TItem>( {
		items,
		loaderCount,
		renderItem,
		renderLoader,
		itemKeyExtractor
	} );

	const itemKey = useItemKey<TItem>();

	const onScroll = useCallback( () => {
		const listElement = ( scrollableContainerRef as React.RefObject<HTMLDivElement> )?.current;

		if ( listElement ) {
			const scrollOffset = listElement.scrollTop;

			if ( scrollOffset + height >= fullListHeight - 50 ) {
				onScrollEndReached?.();
			}
		}
	}, [ onScrollEndReached, scrollableContainerRef ] );

	const innerContainerRef = useRef<HTMLDivElement | null>( null );
	useMarginBottomOnElement( innerContainerRef, bottomEmptySpace );

	useEffect( () => {
		ref.current?.resetAfterIndex( 0, true );
	}, [ items, loaderCount ] );

	return (
		<ReactWindowList
			ref={ref}
			width={width}
			height={height}
			itemCount={rowCount}
			estimatedItemSize={estimatedRowHeight}
			itemSize={itemHeight}
			itemData={listContext}
			itemKey={itemKey}
			overscanCount={overscanRowCount}
			onScroll={onScroll}
			outerRef={scrollableContainerRef}
			innerRef={innerContainerRef}
		>
			{ListRow as ComponentType<ListRowProps<TItem>>}
		</ReactWindowList>
	);
}

export default VariableSizeList;
