import { useState } from 'react';
import { useDispatch } from 'react-redux';
import WorkspaceSystem from '../../systems/WorkspaceSystem';
import CreateOrEditLabelGroupModalPresenter, { type CreateOrEditLabelGroupModalPresenterParams }
	from './CreateOrEditLabelGroupModalPresenter';

const useCreateOrEditLabelGroupModalPresenter = ( {
	workspaceID, mode, labelGroup, onClose
}: Omit<CreateOrEditLabelGroupModalPresenterParams, 'workspaceSystem' | 'snackbarSystem'> ) => {
	const dispatch = useDispatch();
	const workspaceSystem = new WorkspaceSystem( dispatch );

	const createPresenter = () => new CreateOrEditLabelGroupModalPresenter( {
		workspaceID,
		mode,
		labelGroup,
		onClose,
		workspaceSystem
	} );

	const [ presenter ] = useState( createPresenter );

	return presenter;
};

export default useCreateOrEditLabelGroupModalPresenter;
