import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import NavigationPanelButton from '@navigationPanel/components/NavigationPanelButton/NavigationPanelButton';
import useNavigationPanelIsAvailable from '@navigationPanel/hooks/useNavigationPanelIsAvailable';
import useNavigationPanelStore from '@navigationPanel/hooks/useNavigationPanelStore';
import HideOnMobile from 'src/components/HideOnMobile/HideOnMobile';
import { WHITE } from 'src/styles/colors';
import Logo from '../../../assets/img/icons/vb-logo.svg';
import CollapsableBreadcrumbs from '../../CollapsableBreadcrumbs/CollapsableBreadcrumbs';

import './NavigationBreadcrumbs.scss';

export const NavigationBreadcrumbs = ( {
	breadcrumbs, linkURL, hideHomeIcon, hideFirstArrow
} ) => {
	const navigationPanelStore = useNavigationPanelStore();
	const navigationPanelIsAvailable = useNavigationPanelIsAvailable();

	const showVeryBusyIcon = breadcrumbs.length === 0;
	const homeIcon = showVeryBusyIcon ? 'show-vb-logo' : 'show-home';
	return (
		<div className={classNames( 'NavigationBreadcrumbs' )}>
			<HideOnMobile>
				<NavigationPanelButton
					action="open"
					color={WHITE}
				/>
			</HideOnMobile>
			{!hideHomeIcon && (
				<Link
					className={classNames(
						homeIcon,
						{ 'hidden': navigationPanelIsAvailable && navigationPanelStore.isOpen }
					)}
					to={linkURL || '/'}
				>
					{showVeryBusyIcon ? (
						<img
							alt="VeryBusy logo"
							src={Logo}
						/>
					) : (
						<p>Home</p>
					)}
				</Link>
			)}
			{!showVeryBusyIcon && (
				<CollapsableBreadcrumbs breadcrumbs={breadcrumbs} hideFirstArrow={hideFirstArrow} />
			)}
		</div>
	);
};

NavigationBreadcrumbs.propTypes = {
	breadcrumbs: PropTypes.arrayOf( { name: PropTypes.string, url: PropTypes.string } ),
	linkURL: PropTypes.string,
	hideHomeIcon: PropTypes.bool,
	hideFirstArrow: PropTypes.bool
};

NavigationBreadcrumbs.defaultProps = {
	breadcrumbs: [],
	linkURL: undefined,
	hideHomeIcon: false,
	hideFirstArrow: false
};

export default observer( NavigationBreadcrumbs );
