import { NotificationStylesParams } from '@mantine/core';
import { MantineThemeComponent } from '../../types';

const ButtonDefaultConfig: MantineThemeComponent = {
	defaultProps: {
		variant: 'outlined'
	},
	styles: ( theme, { color }: NotificationStylesParams ) => ( {
		root: {
			'padding': 16,
			'&[data-with-border]': {
				borderColor: theme.fn.themeColor( color )
			},
			':before': {
				display: 'none'
			}
		},
		title: {
			fontSize: 14,
			fontWeight: 600
		},
		description: {
			fontSize: 14
		},
		icon: {
			alignSelf: 'flex-start',
			height: 22,
			width: 22,
			marginRight: 8
		},
		closeButton: {
			alignSelf: 'flex-start'
		}
	} ),
	variants: {
		outlined: () => ( {
			description: {
				fontWeight: 400
			},
			closeButton: {
				color: 'black'
			}
		} ),
		filled: ( theme, { color }: NotificationStylesParams ) => ( {
			root: {
				backgroundColor: theme.fn.themeColor( color ),
				color: 'white'
			},
			title: {
				color: 'white',
				display: 'none'
			},
			description: {
				color: 'white',
				fontWeight: 600
			},
			closeButton: {
				'color': 'white',
				'&:hover': {
					color: theme.fn.themeColor( color )
				}
			},
			loader: {
				color: 'white',
				stroke: 'white'
			},
			icon: {
				backgroundColor: 'white',
				color: theme.fn.themeColor( color )
			}
		} )
	}
};

export default ButtonDefaultConfig;
