import { QueryClient } from '@tanstack/react-query';
import QueryClientUpdater, { ReduxStore } from './QueryClientUpdater';
import ReduxStoreUpdater from './ReduxStoreUpdater';
import { UserJson } from '../models/user/user';
import { AssetJSON } from '../modules/projectView/models/asset';
import { FolderJSON, OrganizerType } from '../modules/projectView/models/folder';

export default class ReduxTanStackSync {
	reduxStoreUpdater: ReduxStoreUpdater;
	queryClientUpdater: QueryClientUpdater;

	constructor( { store, queryClient } : { store: ReduxStore, queryClient: QueryClient } ) {
		this.reduxStoreUpdater = new ReduxStoreUpdater( store.dispatch );
		this.queryClientUpdater = new QueryClientUpdater( { store, queryClient } );

		this.queryClientUpdater.start();
	}

	currentUser( currentUser: UserJson ) {
		this.reduxStoreUpdater.updateCurrentUser( currentUser );
	}

	assets( assets: AssetJSON[], { projectID }: { projectID: number } ) {
		this.reduxStoreUpdater.updateAssets( assets, { projectID } );
	}

	assetPrioritySet( assetID: number ) {
		this.reduxStoreUpdater.updateAsset(
			assetID,
			asset => ( { ...asset, rush_priority: true } )
		);
	}

	assetPriorityUnset( assetID: number ) {
		this.reduxStoreUpdater.updateAsset(
			assetID,
			asset => ( { ...asset, rush_priority: false } )
		);
	}

	folders(
		folders: FolderJSON[],
		{ organizerID, organizerType }: { organizerID: number, organizerType: OrganizerType }
	) {
		this.reduxStoreUpdater.updateFolders( folders, { organizerID, organizerType } );
	}

	markWorkspaceOnboardingAsClosed( workspaceID: number ) {
		this.reduxStoreUpdater.markWorkspaceOnboardingAsClosed( workspaceID );
	}
}
