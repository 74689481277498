import SnackbarSystem from '@ui/systems/SnackbarSystem';
import { ASSET_UPLOAD_EVENTS, ITEM_UPLOAD_STATUS } from './DirectFilesUploaderCoordinator';
import {
	cancelAssetUpload,
	createFileUploads, createFolderUploads, updateAssetUploadProgress, updateFolderUploadStatus, updateUploadStatus
} from '../actions/assetUploads';
import { getAssetUpload, getFailedAssetUploadsWithPathsForFolderUpload, getTopLevelAssetUploadIDsForFolderUpload } from '../selectors/assetUploads';
import { isFileNameForRoundTakenError } from '../errors/assetUploads';
import AssetSystem from '../systems/AssetSystem';
import { isInvalidWorkspaceError } from '../errors/workspace';
import PopupSystem from '../systems/PopupSystem';
import PopupFactory from '../factories/PopupFactory';
import { getProject } from '../selectors/projects';
import FolderSystem from '../systems/FolderSystem';
import FolderUploadJob from './FolderUploadJob';

class DirectFileUploaderStoreSubscriber {
	constructor( uploader, store, snackbarSystem = SnackbarSystem.shared() ) {
		this.uploader = uploader;
		this.store = store;
		this.snackbarSystem = snackbarSystem;

		this.folderUploadJobs = [];
	}

	createFolderUploads = ( {
		projectID,
		organizerID,
		organizerType,
		directories,
		parentUploadID,
		onSuccess,
		onFailure
	} ) => {
		const folderSystem = new FolderSystem( this.store.dispatch );

		const folderUploadJobs = directories.map( directory => new FolderUploadJob( {
			projectID,
			organizerID,
			organizerType,
			directory,
			parentUploadID,
			onSuccess: ( folderID ) => {
				onSuccess?.()
				if ( !parentUploadID ) folderSystem.fetchFolder( folderID );
			},
			onFailure: ( upload ) => {
				onFailure?.( upload );
				if ( !parentUploadID ) this._showIncompleteFolderUploadModal( upload );
			},
			onStatusUpdate: ( id, status ) => this.store.dispatch( updateFolderUploadStatus( id, status ) )
		} ) );
		this.folderUploadJobs.push( ...folderUploadJobs );

		folderUploadJobs.forEach( job => job.start( {
			createFolder: params => folderSystem.createFolder( params ),
			createFileUploads: params => this.store.dispatch( createFileUploads( params ) ),
			createFolderUploads: params => this.store.dispatch( createFolderUploads( params ) )
		} ) );

		return folderUploadJobs;
	}

	cancelFolderUpload( folderUploadID ) {
		this._findFolderUploadJob( folderUploadID ).cancel();

		const childAssetUploadsIDs = getTopLevelAssetUploadIDsForFolderUpload(
			this.store.getState(),
			{ folderUploadID }
		);
		childAssetUploadsIDs.forEach( id => this.store.dispatch( cancelAssetUpload( id ) ) );

		this.folderUploadJobs
			.filter( job => job.parentUploadID === folderUploadID )
			.forEach( job => this.cancelFolderUpload( job.id ) );
	}

	cancelAll() {
		this.folderUploadJobs
			.filter( upload => upload.isActive )
			.forEach( upload => this.cancelFolderUpload( upload.id ) );

		this.uploader.cancelAll();
	}

	start() {
		this.uploader.addEventListener(
			ASSET_UPLOAD_EVENTS.UPLOAD_START,
			this._uploadStatusChanged.bind( this, ITEM_UPLOAD_STATUS.UPLOADING )
		);
		this.uploader.addEventListener(
			ASSET_UPLOAD_EVENTS.PROGRESS,
			this._uploadProgressChanged.bind( this )
		);
		this.uploader.addEventListener(
			ASSET_UPLOAD_EVENTS.SUCCESS,
			this._uploadSucceeded.bind( this, ITEM_UPLOAD_STATUS.SUCCEEDED )
		);
		this.uploader.addEventListener(
			ASSET_UPLOAD_EVENTS.ERROR,
			this._uploadStatusChanged.bind( this, ITEM_UPLOAD_STATUS.FAILED )
		);
	}

	_uploadProgressChanged( id, { loaded } ) {
		const {
			bytesUploaded: previousBytesUploaded,
			parentUploadID
		} = getAssetUpload( this.store.getState(), { uploadID: id } );

		this.store.dispatch( updateAssetUploadProgress(
			id,
			{
				totalBytesUploaded: loaded,
				newBytesUploaded: loaded - previousBytesUploaded,
				parentUploadID
			}
		) );
	}

	_uploadStatusChanged( status, id ) {
		this.store.dispatch( updateUploadStatus( id, status ) );
		if ( status === ITEM_UPLOAD_STATUS.FAILED ) {
			const upload = getAssetUpload( this.store.getState(), { uploadID: id } );
			this._notifyParentUploadOfChildUploadFinalization( upload.parentUploadID, { succeeded: false } );
		}
	}

	_uploadSucceeded( _, id, { signed_id: signedId } ) {
		const {
			projectID,
			folderID,
			assetID,
			uploadingRoundForAsset,
			parentUploadID
		} = getAssetUpload( this.store.getState(), { uploadID: id } );

		const parentUploadsJobs = this._getFolderUploadBranchFrom( parentUploadID );
		const parentUploadsData = parentUploadsJobs.map( job => ( {
			uploadedFolderID: job.createdFolderID,
			uploadedAtFolderID: job.folderID
		} ) );

		const assetSystem = new AssetSystem( this.store.dispatch );

		const action = uploadingRoundForAsset
			? () => assetSystem.createRoundFromDirectUpload( id, signedId, assetID, projectID, folderID )
			: () => assetSystem.createAssetFromDirectUpload( id, signedId, projectID, folderID, parentUploadsData );

		action().then( ( { error, payload } ) => {
			if ( error ) {
				this._handleUploadError( { error: payload, projectID } );
				this._notifyParentUploadOfChildUploadFinalization( parentUploadID, { succeeded: false } );
			} else {
				this._notifyParentUploadOfChildUploadFinalization( parentUploadID, { succeeded: true } );
			}
		} );
	}

	_notifyParentUploadOfChildUploadFinalization( parentUploadID, { succeeded } ) {
		if ( !parentUploadID ) return;

		const parentUploadJob = this._findFolderUploadJob( parentUploadID );
		const notificationMessage = succeeded ? 'onChildUploadSucceeded' : 'onChildUploadFailed';
		parentUploadJob?.[ notificationMessage ]();
	}

	_handleUploadError( { error, projectID } ) {
		if ( isFileNameForRoundTakenError( error ) ) {
			this._showErrorMessage(
				'Upload Error',
				'There\'s already another file with the same name. Please rename it to continue.'
			);
		}

		if ( isInvalidWorkspaceError( error ) ) {
			const workspace = getProject( this.store.getState(), { projectID } )?.workspace;
			if ( !workspace ) return;
			PopupFactory.storageLimitReachedPopup( { popupSystem: this._popupSystem, workspace } );
		}
	}

	_showErrorMessage( title, description ) {
		this.snackbarSystem.showErrorMessage( { title, description } );
	}

	_showIncompleteFolderUploadModal( folderUpload ) {
		const failedUploadsInsideFolder = getFailedAssetUploadsWithPathsForFolderUpload(
			this.store.getState(),
			{ folderUploadID: folderUpload.id }
		);

		PopupFactory.incompleteFolderUploadPopup( {
			popupSystem: this._popupSystem, failedUploads: failedUploadsInsideFolder
		} );
	}

	get _popupSystem() {
		return new PopupSystem( this.store.dispatch );
	}

	_findFolderUploadJob( id ) {
		return this.folderUploadJobs.find( job => job.id === id );
	}

	_getFolderUploadBranchFrom( folderUploadID ) {
		const thisJob = this._findFolderUploadJob( folderUploadID );
		if ( !thisJob ) return [];
		return [ thisJob, ...this._getFolderUploadBranchFrom( thisJob.parentUploadID ) ];
	}
}

export default DirectFileUploaderStoreSubscriber;
