import Icon from '@ui/components/Icon/Icon';

import styles from './EmptyPlaceholder.module.scss';

const EmptyPlaceholder = () => (
	<div className={styles.EmptyPlaceholder}>
		<div className="icon-container">
			<Icon icon="apps" size={16} color="black" />
		</div>
		<h3>No Active Projects Yet</h3>
		<p>Create your first projects or get invited to collaborate to see your projects displayed here.</p>
	</div>
);

export default EmptyPlaceholder;
