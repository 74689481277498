import { useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import useSnackbarSystem from '@ui/systems/useSnackbarSystem';

import useAutoupdatePresenterProperty from '../../hooks/useAutoupdatePresenterProperty';
import PlanSettingsPresenter from './PlanSettingsPresenter';
import { getWorkspace } from '../../selectors/workspaces';
import {
	reactivateSubscription as reactivateSubscriptionAction,
	cancelSubscription as cancelSubscriptionAction,
	createSubscription as createSubscriptionAction,
	fetchCurrentUserWorkspaces,
	downgradeSubscription as downgradeSubscriptionAction,
	cancelDowngradeSubscription as cancelDowngradeSubscriptionAction
} from '../../actions/workspaces';

import PopupSystem from '../../systems/PopupSystem';

const usePlanSettingsPresenter = ( { workspaceID } ) => {
	const history = useHistory();
	const snackbarSystem = useSnackbarSystem();

	const dispatch = useDispatch();
	// Redux State
	const workspace = useSelector(
		state => getWorkspace( state, { workspaceID } )
	);

	const createPresenter = () => {
		// Redux Actions
		const cancelSubscription = ( ...args ) => dispatch(
			cancelSubscriptionAction( ...args )
		);
		const reactivateSubscription = ( ...args ) => dispatch(
			reactivateSubscriptionAction( ...args )
		);
		const createSubscription = ( ...args ) => dispatch(
			createSubscriptionAction( ...args )
		);
		const fetchWorkspaces = () => dispatch(	fetchCurrentUserWorkspaces() );
		const downgradeSubscription = ( ...args ) => dispatch(
			downgradeSubscriptionAction( ...args )
		);
		const cancelDowngradeSubscription = ( ...args ) => dispatch(
			cancelDowngradeSubscriptionAction( ...args )
		);

		const popupSystem = new PopupSystem( dispatch );

		return new PlanSettingsPresenter( {
			popupSystem,
			cancelSubscription,
			reactivateSubscription,
			createSubscription,
			workspace,
			fetchWorkspaces,
			snackbarSystem,
			downgradeSubscription,
			cancelDowngradeSubscription,
			history
		} );
	};

	const [ presenter ] = useState( createPresenter );

	useAutoupdatePresenterProperty( { presenter, name: 'workspace', value: workspace } );

	return presenter;
};

export default usePlanSettingsPresenter;
