import { Component } from 'react';
import { GlobalHotKeys } from 'react-hotkeys';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { GithubPicker } from 'react-color';
import Slider from 'rc-slider';
import Icon from '@ui/components/Icon/Icon';
import { ReactComponent as ChromaticCircle } from '../../../assets/img/icons/chromatic-circle.svg';

import './AssetEditingToolbar.scss';
import { PRIMARY_BASE, TEXT_GREY } from '../../../styles/colors';
import { isMac } from '../../../lib/osUtils';
import { preventDefaultAndCall } from '../../../new_arch/lib/eventUtils';
import Tooltip from '../../UI/Tooltip/Tooltip';

export const AVAILABLE_COLORS = [
	'#b80000', '#ff5722', '#fccb00', '#008b02',
	'#004dcf', '#7b1fa2', '#f2f3f2', '#000000'
];

const needsContrastCorrection = color => color === '#f2f3f2';

export const MINIMUM_VALUE = 1;
export const MAXIMUM_SIZE = 7;

export const SELECTION_TOOL = 'selection';
export const BRUSH_TOOL = 'brush';
export const LINE_TOOL = 'line';
export const ARROW_TOOL = 'arrow';
export const RECT_TOOL = 'rect';

const toolIcons = {
	[ SELECTION_TOOL ]: 'pointer',
	[ BRUSH_TOOL ]: 'brush',
	[ LINE_TOOL ]: 'line',
	[ ARROW_TOOL ]: 'arrow-outward',
	[ RECT_TOOL ]: 'square'
};

const toolTooltips = {
	[ BRUSH_TOOL ]: 'Draw “Cmd / Ctrl D”',
	[ LINE_TOOL ]: 'Line “Cmd / Ctrl L”',
	[ ARROW_TOOL ]: 'Arrow “Cmd / Ctrl A”',
	[ RECT_TOOL ]: 'Square “Cmd / Ctrl S”'
};

class AssetEditingToolbar extends Component {
	constructor( props ) {
		super( props );

		this.state = { showingColorPicker: false };

		this._onToggleColorPickClicked = this._onToggleColorPickClicked.bind( this );
		this._onClickOutside = this._onClickOutside.bind( this );
		this._onColorChanged = this._onColorChanged.bind( this );
		this._onSizePercentageChanged = this._onSizePercentageChanged.bind( this );
		this._onUndoClicked = this._onUndoClicked.bind( this );
	}

	componentDidMount() {
		document.addEventListener( 'mousedown', this._onClickOutside );
	}

	componentWillUnmount() {
		document.removeEventListener( 'mousedown', this._onClickOutside );
	}

	closeColorPicker() {
		this.setState( { showingColorPicker: false } );
	}

	// Events handlers
	_onToggleColorPickClicked() {
		this.setState( prevState => (
			{ showingColorPicker: !prevState.showingColorPicker }
		) );
	}

	_onClickOutside( event ) {
		if (
			this.colorPickerWrapper && !this.colorPickerWrapper.contains( event.target )
		) {
			this.closeColorPicker();
		}
	}

	_onColorChanged( color ) {
		const { selectedTool, onToolSelect, onColorSelect } = this.props;

		onColorSelect( color.hex );
		if ( selectedTool === SELECTION_TOOL ) { onToolSelect( BRUSH_TOOL ); }
	}

	_onSizePercentageChanged( percentage ) {
		this.props.onSizeSelect(
			MINIMUM_VALUE + ( ( MAXIMUM_SIZE * Math.abs( 100 - percentage ) ) / 100 )
		);
	}

	_onUndoClicked() {
		this.props.onUndoClick();
	}

	// Render
	_toolButtonStylesFor( toolName ) {
		const { selectedTool, selectedColor } = this.props;

		if ( toolName !== selectedTool ) {
			return {
				buttonStyle: undefined,
				iconColor: TEXT_GREY
			};
		}
		if ( toolName === SELECTION_TOOL ) {
			return {
				buttonStyle: { backgroundColor: PRIMARY_BASE },
				iconColor: 'white'
			};
		}
		if ( needsContrastCorrection( selectedColor ) ) {
			return {
				buttonStyle: {
					backgroundColor: selectedColor,
					border: `1px solid ${TEXT_GREY}`
				},
				iconColor: TEXT_GREY
			}
		}

		return {
			buttonStyle: { backgroundColor: selectedColor },
			iconColor: 'white'
		}
	}

	_renderToolButton( toolName ) {
		const { onToolSelect, disabled } = this.props;
		const { buttonStyle, iconColor } = this._toolButtonStylesFor( toolName );
		const tooltipText = toolTooltips[ toolName ];

		return (
			<Tooltip title={tooltipText} disabled={!tooltipText}>
				<button
					type="button"
					className="tool-button"
					style={buttonStyle}
					onClick={() => onToolSelect( toolName )}
					disabled={disabled}
				>
					<Icon
						icon={toolIcons[ toolName ]}
						size={20}
						color={iconColor}
					/>
				</button>
			</Tooltip>
		);
	}

	_renderColorButton() {
		const { selectedColor, disabled } = this.props;
		const { showingColorPicker } = this.state;

		return (
			<div className="color-picker-container">
				<button
					type="button"
					className="color-picker-opener"
					onClick={this._onToggleColorPickClicked}
					disabled={disabled}
				>
					<ChromaticCircle className="ChromaticCircle" style={{ '--ringColor': selectedColor }} />
				</button>
				{showingColorPicker && (
					<div
						ref={( ref ) => { this.colorPickerWrapper = ref; }}
						className="picker-wrapper"
					>
						<GithubPicker
							colors={AVAILABLE_COLORS}
							color={selectedColor}
							onChange={this._onColorChanged}
						/>
					</div>
				)}
			</div>
		);
	}

	_renderSizeSlider() {
		const { selectedSize, disabled } = this.props;
		const percentage = ( ( selectedSize - MINIMUM_VALUE ) / MAXIMUM_SIZE ) * 100;

		return (
			<div className="triangle-container">
				<Slider
					value={percentage}
					onChange={this._onSizePercentageChanged}
					handle={( { offset, dragging } ) => (
						<div
							className={classNames( 'handle', { dragging } )}
							style={{ right: `${offset}%` }}
						>
							<Icon icon="lens" size={15} color="secondary.4" />
						</div>
					)}
					disabled={disabled}
				/>
			</div>
		);
	}

	_renderUndoButton() {
		const { undoEnabled, disabled } = this.props;

		return (
			<button
				className="undo"
				disabled={!undoEnabled || disabled}
				type="button"
				onClick={this._onUndoClicked}
			>
				<Icon icon="undo" size={20} />
			</button>
		)
	}

	get _keyMapForTools() {
		return {
			arrow: {
				sequence: `${isMac() ? 'command+a' : 'ctrl+a'}`,
				action: 'keydown'
			},
			brush: {
				sequence: `${isMac() ? 'command+d' : 'ctrl+d'}`,
				action: 'keydown'
			},
			line: {
				sequence: `${isMac() ? 'command+l' : 'ctrl+l'}`,
				action: 'keydown'
			},
			rect: {
				sequence: `${isMac() ? 'command+s' : 'ctrl+s'}`,
				action: 'keydown'
			}
		}
	}

	get _keyHandlersForTools() {
		const { onToolSelect } = this.props;

		return {
			arrow: preventDefaultAndCall( () => onToolSelect( ARROW_TOOL ) ),
			brush: preventDefaultAndCall( () => onToolSelect( BRUSH_TOOL ) ),
			line: preventDefaultAndCall( () => onToolSelect( LINE_TOOL ) ),
			rect: preventDefaultAndCall( () => onToolSelect( RECT_TOOL ) )
		}
	}

	render() {
		return (
			<GlobalHotKeys keyMap={this._keyMapForTools} handlers={this._keyHandlersForTools}>
				<div className="AssetEditingToolbar">
					{this._renderToolButton( SELECTION_TOOL )}
					<div className="separator" />
					<div className="shapes">
						{this._renderToolButton( BRUSH_TOOL )}
						{this._renderToolButton( LINE_TOOL )}
						{this._renderToolButton( ARROW_TOOL )}
						{this._renderToolButton( RECT_TOOL )}
					</div>
					{this._renderSizeSlider()}
					{this._renderColorButton()}
					{this._renderUndoButton()}
				</div>
			</GlobalHotKeys>
		);
	}
}

AssetEditingToolbar.propTypes = {
	selectedTool: PropTypes.string,
	selectedColor: PropTypes.string,
	selectedSize: PropTypes.number,
	undoEnabled: PropTypes.bool,
	onToolSelect: PropTypes.func,
	onColorSelect: PropTypes.func,
	onSizeSelect: PropTypes.func,
	onUndoClick: PropTypes.func,
	disabled: PropTypes.bool
};

AssetEditingToolbar.defaultProps = {
	selectedTool: SELECTION_TOOL,
	selectedColor: '#34a3db',
	selectedSize: 2,
	undoEnabled: false,
	onToolSelect: () => {},
	onColorSelect: () => {},
	onSizeSelect: () => {},
	onUndoClick: () => {},
	disabled: false
};

export default AssetEditingToolbar;
