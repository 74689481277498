import { useEffect } from 'react';
import PropTypes from 'prop-types';
import SnackbarSystem from '@ui/systems/SnackbarSystem';

import { getWorkspaceContactGroups } from '../../selectors/contactGroups';
import { connectComponent } from '../../lib/connectComponent';
import WorkspaceContactGroups from '../../components/WorkspaceContactGroups/WorkspaceContactGroups';
import WorkspaceSystem from '../../systems/WorkspaceSystem';
import ContactGroupPropType from '../../types/contactGroup';
import PopupSystem from '../../systems/PopupSystem';
import AsyncActionPresenterFactory from '../../factories/AsyncActionPresenterFactory';

export const WorkspaceContactGroupsContainer = ( {
	workspaceID, contactGroups, workspaceSystem, popupSystem, snackbarSystem
} ) => {
	useEffect( () => {
		workspaceSystem.getContactsGroups( workspaceID );
	}, [ workspaceID ] );

	const onDeleteContactGroupClick = ( contactGroup ) => {
		AsyncActionPresenterFactory.forDeleteContactGroup( {
			workspaceID, contactGroup, workspaceSystem, popupSystem, snackbarSystem
		} ).trigger();
	}

	return (
		<WorkspaceContactGroups
			workspaceID={workspaceID}
			contactGroups={contactGroups}
			onDeleteContactGroupClick={onDeleteContactGroupClick}
		/>
	);
}

WorkspaceContactGroupsContainer.propTypes = {
	workspaceID: PropTypes.number,
	contactGroups: PropTypes.arrayOf( ContactGroupPropType ),
	workspaceSystem: PropTypes.instanceOf( WorkspaceSystem ).isRequired,
	popupSystem: PropTypes.instanceOf( PopupSystem ).isRequired,
	snackbarSystem: PropTypes.instanceOf( SnackbarSystem ).isRequired
};

WorkspaceContactGroupsContainer.defaultProps = {
	workspaceID: undefined,
	contactGroups: undefined
};

export default connectComponent( ( state, props ) => ( {
	contactGroups: getWorkspaceContactGroups( state, props )
} ) )( WorkspaceContactGroupsContainer );
