import PropTypes from 'prop-types';
import classNames from 'classnames';

import Icon from '@ui/components/Icon/Icon';

import CreditCard from '../../../entities/creditCard';

import './CreditCardInformation.scss';

const CreditCardInformation = ( { card } ) => {
	const empty = !card;
	const isExpired = !empty && card.isExpired;

	const content = empty
		? <p>No credit card information</p>
		: (
			<div className="credit-card-information-body">
				<div className={classNames( 'credit-card-information-body-right', { isExpired } )}>
					<div className="title">
						<Icon className="credit-card-icon" icon="credit_card" size={20} />
						<h4>{card.ownerName}</h4>
					</div>
					<p>{card.hiddenNumber}</p>
					<p>{`${isExpired ? 'Expired' : 'Expires'} on ${card.formattedExpiration}`}</p>
				</div>
			</div>
		);

	return (
		<div className={classNames( 'CreditCardInformation', { empty } )}>
			{content}
		</div>
	);
};

CreditCardInformation.propTypes = {
	card: PropTypes.instanceOf( CreditCard )
};

CreditCardInformation.defaultProps = {
	card: undefined
};

export default CreditCardInformation;
