import { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useStripe } from '@stripe/react-stripe-js';
import SnackbarSystem from '@ui/systems/SnackbarSystem';

import { connectComponent } from '../../lib/connectComponent';

import WorkspaceSubscriptionCheckoutModal from '../../modals/WorkspaceSubscriptionCheckoutModal/WorkspaceSubscriptionCheckoutModal';
import { getWorkspace, getCreditCardForWorkspace } from '../../selectors/workspaces';
import { getBillingInformationForWorkspace } from '../../selectors/workspacesBillingInformation';
import { actionResultIsError } from '../../lib/reduxUtils';
import Workspace from '../../entities/workspace';
import PricingPlanPrice from '../../entities/pricing/pricingPlanPrice';
import CheckoutSubscriptionOperationFactory from '../../factories/checkoutOperationFactory';
import WorkspaceBillingInformation from '../../entities/workspaceBillingInformation';
import CreditCard from '../../entities/creditCard';
import { isInvalidCardError, isCardDeclinedError, getErrorDescription, isInvalidPromoCodeError } from '../../errors/checkout';

import { trackNavigatedToCheckoutModal } from '../../services/tracker/trackers/navigation';
import { isNotAllowedOrNotFound } from '../../errors/common';
import SnackbarFactory from '../../factories/SnackbarFactory';

export const ERROR_ON_CHECKOUT_DEFAULT_TITLE = 'We couldn\'t process your payment';
export const PROMO_CODE_ERROR_ON_CHECKOUT_TITLE = 'We couldn\'t process the discount';

export const WorkspaceSubscriptionCheckoutModalContainer = ( {
	onClose,
	billingInformation,
	creditCard,
	createSubscription,
	createWorkspace,
	onSuccessfulDialogAccepted,
	showErrorDialog,
	workspace,
	pricingPlanPrice,
	fetchCurrentUserWorkspaces,
	snackbarSystem
} ) => {
	useEffect( () => trackNavigatedToCheckoutModal(), [] );

	const stripe = useStripe();
	const [ sending, setSending ] = useState( false );
	const [ promoCodeID, setPromoCodeID ] = useState( null );

	const operationFactory = new CheckoutSubscriptionOperationFactory(
		createSubscription, createWorkspace
	);
	const operation = operationFactory.operationForWorkspace( workspace );

	const showSuccessfulDialog = () => {
		SnackbarFactory.forSubscriptionPurchaseSuccess( snackbarSystem );
		onSuccessfulDialogAccepted();
	};

	const onCheckoutCompleted = () => {
		fetchCurrentUserWorkspaces();
		showSuccessfulDialog();
	};

	const onCheckout = useCallback(
		( newBillingInformation, newPaymentMethodParams ) => {
			setSending( true );
			const isCreatingNewPaymentMethod = !!newPaymentMethodParams;

			const managePaymentMethod = isCreatingNewPaymentMethod
				? () => stripe.createPaymentMethod( {
					type: 'card',
					card: newPaymentMethodParams.cardElement,
					billing_details: {
						name: newPaymentMethodParams.cardholderName
					}
				} )
				: () => Promise.resolve( { paymentMethod: null } );

			managePaymentMethod()
				.then( ( { paymentMethod } ) => (
					operation(
						pricingPlanPrice,
						newBillingInformation,
						paymentMethod ? paymentMethod.id : null,
						promoCodeID
					).then( ( result ) => {
						if ( actionResultIsError( result ) ) {
							throw result;
						}

						onCheckoutCompleted();
					} )
				) )
				.catch( ( error ) => {
					setSending( false );
					const { payload } = error;
					if ( !payload ) {
						return !isNotAllowedOrNotFound( payload ) && showErrorDialog(
							ERROR_ON_CHECKOUT_DEFAULT_TITLE,
							'Please try again later.'
						);
					}

					const shouldStayOnCheckoutOnErrorClose = (
						isCardDeclinedError( payload )
						|| isInvalidCardError( payload )
						|| isInvalidPromoCodeError( payload )
					);
					const errorTitle = isInvalidPromoCodeError( payload )
						? PROMO_CODE_ERROR_ON_CHECKOUT_TITLE : ERROR_ON_CHECKOUT_DEFAULT_TITLE;
					return !isNotAllowedOrNotFound( payload ) && showErrorDialog(
						errorTitle,
						getErrorDescription( payload )
					)
						.then( () => {
							if ( !shouldStayOnCheckoutOnErrorClose ) {
								onClose();
							}
						} );
				} )
				.finally( () => {
					setSending( false );
				} );
		},
		[ stripe, workspace, pricingPlanPrice?.id, promoCodeID ]
	);

	return (
		<WorkspaceSubscriptionCheckoutModal
			initialBillingInformation={billingInformation}
			initialCreditCard={creditCard}
			onClose={onClose}
			onCheckout={onCheckout}
			onSetPromoCodeID={setPromoCodeID}
			sending={sending}
			pricingPlanPrice={pricingPlanPrice}
			workspace={workspace}
		/>
	);
};

WorkspaceSubscriptionCheckoutModalContainer.propTypes = {
	onClose: PropTypes.func,
	billingInformation: PropTypes.instanceOf( WorkspaceBillingInformation ),
	creditCard: PropTypes.instanceOf( CreditCard ),
	createSubscription: PropTypes.func,
	createWorkspace: PropTypes.func,
	onSuccessfulDialogAccepted: PropTypes.func,
	showErrorDialog: PropTypes.func,
	fetchCurrentUserWorkspaces: PropTypes.func,
	workspace: PropTypes.instanceOf( Workspace ),
	pricingPlanPrice: PropTypes.instanceOf( PricingPlanPrice ).isRequired,
	snackbarSystem: PropTypes.instanceOf( SnackbarSystem ).isRequired
};

WorkspaceSubscriptionCheckoutModalContainer.defaultProps = {
	onClose: () => {},
	billingInformation: undefined,
	creditCard: undefined,
	createSubscription: () => {},
	createWorkspace: () => {},
	onSuccessfulDialogAccepted: () => {},
	showErrorDialog: () => {},
	fetchCurrentUserWorkspaces: () => {},
	workspace: undefined
};

export default connectComponent( ( state, props ) => ( {
	workspace: getWorkspace( state, props ),
	billingInformation: getBillingInformationForWorkspace( state, props ),
	creditCard: getCreditCardForWorkspace( state, props )
} ) )( WorkspaceSubscriptionCheckoutModalContainer );
