import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	getAssetActiveUploadsCount, getAssetsUploadsTotalProgress, getAssetUploadsCount,
	getAssetUploadsProjectIDs, getETAForAllAssetUploads
} from 'src/selectors/assetUploads';
import { cancelAllAssetUploads, clearFinishedUploads } from 'src/actions/assetUploads';

import useReduxFileSelectorForAssetUpload
	from 'src/new_arch/hooks/useReduxFileSelectorForAssetUpload';

import Header from './Header/Header';
import Footer from './Footer/Footer';
import EmptyPlaceholder from './EmptyPlaceholder/EmptyPlaceholder';
import UploadsList from './UploadsList/UploadsList';

import './AssetUploadsBox.scss';

export type AssetUploadsBoxProps = {
	projectID?: number,
	folderID?: number | null
};

const AssetUploadsBox = ( {
	projectID, folderID = null
}: AssetUploadsBoxProps ) => {
	const uploadProjectIDs = useSelector( getAssetUploadsProjectIDs );
	const assetUploadsCount = useSelector( getAssetUploadsCount );
	const assetActiveUploadsCount = useSelector( getAssetActiveUploadsCount );
	const totalTimeRemaining = useSelector( getETAForAllAssetUploads );
	const totalProgress = useSelector( getAssetsUploadsTotalProgress );

	const hasUploads = assetUploadsCount > 0;
	const isUploading = assetActiveUploadsCount > 0;
	const isInsideAproject = !!projectID;

	const openFileSelectorForAssetUpload = useReduxFileSelectorForAssetUpload( {
		projectID: projectID || 0, folderID
	} )
	const onStartUploadClicked = useCallback(
		() => openFileSelectorForAssetUpload( { directoryMode: false } ),
		[ openFileSelectorForAssetUpload ]
	);
	const dispatch = useDispatch();
	const onCancelAllClick = useCallback( () => dispatch( cancelAllAssetUploads() ), [ dispatch ] );
	const onClearAllClick = useCallback( () => dispatch( clearFinishedUploads() ), [ dispatch ] );

	return (
		<div className="AssetUploadsBox">
			<Header
				uploadingCount={assetActiveUploadsCount}
				uploadingProgress={totalProgress}
				uploadingETA={totalTimeRemaining}
			/>
			{hasUploads ? (
				<UploadsList projectIDs={uploadProjectIDs} />
			) : (
				<EmptyPlaceholder
					isInsideAproject={isInsideAproject}
					onStartUploadClick={onStartUploadClicked}
				/>
			)}
			<Footer
				hasUploads={hasUploads}
				isUploading={isUploading}
				onCancelAllClick={onCancelAllClick}
				onClearAllClick={onClearAllClick}
			/>
		</div>
	);
};

export default AssetUploadsBox;
