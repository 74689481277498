import { NOTIFICATIONS_MARK_AS_SEEN_URL, NOTIFICATIONS_URL, PROJECTS_URL } from 'src/config/urls';
import Api from 'src/services/Api';

import { NotificationJSON, NotificationType } from '@notifications/models/Notification';
import { ProjectWithNotificationCountJSON }
	from '@notifications/models/ProjectWithNotificationCount';

type FetchProjectNotificationsParams = {
	projectID: number;
	filter?: NotificationType;
	page: number;
	olderThan: Date;
};

export type NotificationsFetchResult = {
	notifications: NotificationJSON[],
	current_page: number,
	older_than: string,
	total_pages: number
};
export const NOTIFICATIONS_PER_PAGE = 20;

export default class NotificationSystem {
	private api: Api;

	constructor( { api }: { api: Api } ) {
		this.api = api;
	}

	async fetchProjectsWithUnseenNotifications(): Promise<ProjectWithNotificationCountJSON[]> {
		const { response } = await (
			this.api.get( NOTIFICATIONS_URL ) as Promise<{ response: ProjectWithNotificationCountJSON[] }>
		);

		return response;
	}

	async fetchProjectNotifications(
		{ projectID, filter, page, olderThan }: FetchProjectNotificationsParams
	): Promise<NotificationsFetchResult> {
		const params = new URLSearchParams( {
			page: page.toString(),
			per_page: NOTIFICATIONS_PER_PAGE.toString(),
			older_than: olderThan.toISOString()
		} );
		if ( filter ) params.set( 'filter_type', filter );

		const url = `${PROJECTS_URL}/${projectID}/notifications?${params.toString()}`;

		const { response } = await (
			this.api.get( url ) as Promise<{ response: NotificationsFetchResult }>
		);

		return response;
	}

	async markNotificationsAsSeen( { projectID }: { projectID: number } ): Promise<void> {
		await (
			this.api.post( NOTIFICATIONS_MARK_AS_SEEN_URL, { project_id: projectID } ) as Promise<void>
		);
	}

	async markNotificationAsClicked( { notificationID }: { notificationID: number } ): Promise<void> {
		await (
			this.api.post( `${NOTIFICATIONS_URL}/${notificationID}/mark_as_clicked` ) as Promise<void>
		);
	}
}
