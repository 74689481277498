import PropTypes from 'prop-types';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import classNames from 'classnames';
import 'react-day-picker/lib/style.css';
import Icon from '@ui/components/Icon/Icon';
import { formatDate } from '../../AddNewTask/AddNewTask';

import './DatePicker.scss';

const DatePicker = ( {
	displayText, refHandler, value,	dayChangeHandler,
	disabledDays, name, error, placeholder, decreasedHeigth,
	showOnTop, disabled
} ) => {
	const defaultName = displayText.replace( /\s/, '_' );
	let resultedValue = value === null ? undefined : value;
	return (
		<div className={classNames( 'DatePicker', { error, decreasedHeigth, showOnTop } )}>
			{displayText && <label htmlFor={name || defaultName}>{displayText}</label>}
			<div className="icon-and-input">
				<Icon icon="date_range" size={20} color="secondary.4" />
				<DayPickerInput
					dayPickerProps={{
						fixedWeeks: true,
						disabledDays
					}}
					inputProps={{
						name: name || defaultName,
						autoComplete: 'off',
						disabled
					}}
					className="input"
					formatDate={formatDate}
					placeholder={placeholder}
					ref={refHandler}
					value={resultedValue}
					onDayChange={dayChangeHandler}
				/>
			</div>
		</div>
	);
};

DatePicker.propTypes = {
	name: PropTypes.string,
	displayText: PropTypes.string,
	value: PropTypes.oneOfType( [
		PropTypes.instanceOf( Date ),
		PropTypes.string
	] ),
	refHandler: PropTypes.any,
	dayChangeHandler: PropTypes.func,
	disabledDays: PropTypes.shape( {
		before: PropTypes.instanceOf( Date ),
		after: PropTypes.instanceOf( Date )
	} ).isRequired,
	error: PropTypes.bool,
	placeholder: PropTypes.string,
	decreasedHeigth: PropTypes.bool,
	showOnTop: PropTypes.bool,
	disabled: PropTypes.bool
};

DatePicker.defaultProps = {
	name: undefined,
	displayText: '',
	value: undefined,
	refHandler: () => {},
	dayChangeHandler: () => {},
	error: false,
	placeholder: '',
	decreasedHeigth: false,
	showOnTop: false,
	disabled: false
};

export default DatePicker;
