import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from '@ui/components/Icon/Icon';
import AssetRound from '../../../entities/assetRound';

import './AssetRoundsMenuItem.scss';

export const AssetRoundsMenuItem = ( {
	round, isSelected, onSelect, iconSize, iconColor
} ) => (

	<button
		className={classNames( 'AssetRoundsMenuItem', { selected: isSelected } )}
		onClick={() => onSelect( round )}
	>
		<p className="round-info">Round {round.number}</p>
		{isSelected && (
			<Icon icon="check" size={iconSize} color={iconColor} />
		)}
	</button>
);

AssetRoundsMenuItem.propTypes = {
	round: PropTypes.instanceOf( AssetRound ).isRequired,
	isSelected: PropTypes.bool,
	onSelect: PropTypes.func,
	iconSize: PropTypes.number,
	iconColor: PropTypes.string
};

AssetRoundsMenuItem.defaultProps = {
	isSelected: false,
	onSelect: () => { },
	iconSize: 16,
	iconColor: 'white'
};

export default AssetRoundsMenuItem;
