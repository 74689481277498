import PropTypes from 'prop-types';

import Icon from '@ui/components/Icon/Icon';

import './BulletedList.scss';
import { PRIMARY_BASE } from '../../styles/colors';

const BulletedList = ( { icon, children, ...iconProps } ) => (
	<ul className="BulletedList">
		{children.map(
			child => (
				<li>
					{' '}
					<Icon className="bullet-point" icon={child.icon || icon} {...iconProps} />
					{' '}
					{child.node || child}
				</li>
			)
		)}
	</ul>
);

BulletedList.propTypes = {
	iconProps: PropTypes.any,
	icon: PropTypes.any,
	children: PropTypes.arrayOf( PropTypes.node ).isRequired
};

BulletedList.defaultProps = {
	iconProps: { color: PRIMARY_BASE },
	icon: 'check'
};

export default BulletedList;
