import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from '@ui/components/Icon/Icon';
import { SECONDARY_BASE } from '../../../styles/colors';

import './Checkbox.scss';

const uncheckedButtonConfig = {
	key: 'checkbox_unchecked',
	className: 'default-state',
	icon: 'check_box_outline_blank'
};

const checkedButtonConfig = {
	key: 'checkbox_checked',
	className: 'check-state',
	icon: 'check_box'
};

const toggleButtonConfigForState = isChecked => (
	isChecked ? checkedButtonConfig : uncheckedButtonConfig
);

const Checkbox = ( {
	label, value, onChange, checkedColor, rtl, disabled, dataTestId
} ) => {
	const toggleButton = toggleButtonConfigForState( value );

	const iconColor = value ? checkedColor : SECONDARY_BASE;

	return (
		<button type="button" data-testid={dataTestId} className={classNames( 'Checkbox', toggleButton.className, { checked: value, rtl, disabled } )} disabled={disabled} onClick={() => onChange( !value )}>
			<div className="checkbox">
				<Icon
					key={toggleButton.key}
					icon={toggleButton.icon}
					color={iconColor}
					size={18}
				/>
			</div>
			{label && <p>{label}</p>}
		</button>
	);
};

Checkbox.propTypes = {
	label: PropTypes.node,
	value: PropTypes.bool,
	onChange: PropTypes.func,
	checkedColor: PropTypes.string,
	rtl: PropTypes.bool,
	disabled: PropTypes.bool,
	dataTestId: PropTypes.string
};

Checkbox.defaultProps = {
	value: false,
	onChange: () => {},
	checkedColor: SECONDARY_BASE,
	rtl: false,
	disabled: false,
	label: null,
	dataTestId: null
};

export default Checkbox;
