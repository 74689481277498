/* eslint-disable react/no-unused-prop-types */
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import NavigationBreadcrumbs from '../../components/UI/NavigationBreadcrumbs/NavigationBreadcrumbs';
import { connectComponent } from '../../lib/connectComponent';
import { getAsset } from '../../selectors/assets';
import { getFolder } from '../../selectors/folders';
import { getProject } from '../../selectors/projects';

import Asset from '../../entities/asset';
import Folder from '../../entities/folder';
import Project from '../../entities/project';
import BreadcrumbLevelFactory from '../../factories/breadcrumbsFactory';
import HistoryPropType from '../../types/history';

export const NavigationBreadcrumbsContainer = ( {
	project,
	folder,
	asset,
	hideHomeIcon,
	history
} ) => {
	const ancestors = folder?.ancestors?.length > 0
		? folder?.ancestors.map( ancestor => ( { name: ancestor.name, url: ancestor.url } ) ) : [];
	const inHiddenProjects = history.location.pathname === '/hidden_projects' || project?.status === 'archived';

	const breadcrumbs = BreadcrumbLevelFactory.arrayFrom( {
		levels: [
			inHiddenProjects ? { name: 'Hidden projects', url: '/hidden_projects' } : undefined,
			project ? { name: project.name, url: project.url } : undefined,
			...ancestors,
			folder ? { name: folder.name, url: folder.url } : undefined,
			asset
		],
		history
	} );

	return (
		<NavigationBreadcrumbs
			breadcrumbs={breadcrumbs}
			linkURL={project?.projectDashboardUrl}
			hideHomeIcon={hideHomeIcon}
			hideFirstArrow={hideHomeIcon}
		/>
	);
};

NavigationBreadcrumbsContainer.propTypes = {
	project: PropTypes.instanceOf( Project ),
	folder: PropTypes.instanceOf( Folder ),
	asset: PropTypes.instanceOf( Asset ),
	projectID: PropTypes.number,
	folderID: PropTypes.number,
	assetID: PropTypes.number,
	hideHomeIcon: PropTypes.bool,
	history: HistoryPropType.isRequired
};

NavigationBreadcrumbsContainer.defaultProps = {
	project: undefined,
	folder: undefined,
	asset: undefined,
	projectID: undefined,
	folderID: undefined,
	assetID: undefined,
	hideHomeIcon: false
};

export default connectComponent( ( state, props ) => ( {
	asset: getAsset( state, props ),
	project: getProject( state, props ),
	folder: getFolder( state, props )
} ) )( withRouter( NavigationBreadcrumbsContainer ) );
