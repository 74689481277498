import { useState } from 'react';
import Icon from '@ui/components/Icon/Icon';

import useToggle from '../../hooks/useToggle';

import Switch from '../UI/Switch/Switch';
import ShareLinkButton from '../ShareLinkButton/ShareLinkButton';
import Tooltip from '../UI/Tooltip/Tooltip';

import './ShareReviewLink.scss';
import { trackProjectLinkShared } from '../../services/tracker/trackers/projects';

export type Shareable = {
	shareLink: ( { withDownloads } : { withDownloads: boolean } ) => string
}

const ShareReviewLink = ( { shareable }: { shareable: Shareable } ) => {
	const [ allowsDownload, toggleAllowsDownload ] = useToggle( true );
	const [ showSuccessMessage, setShowSuccessMessage ] = useState( false );
	const shareLink = `${document.location.origin}/${shareable.shareLink( { withDownloads: allowsDownload } )}`;

	const copyShareLink = () => {
		navigator.clipboard.writeText( shareLink );
		setShowSuccessMessage( true );
		trackProjectLinkShared( { allowsDownload } );
		setTimeout( () => setShowSuccessMessage( false ), 2000 );
	};

	const tooltipData = (
		<p className="tooltip-text">Anyone who has this link can download<br />the most recent round of images</p>
	);

	return (
		<div className="ShareReviewLink">
			<div className="DownloadConfigContainer">
				<h5>Permissions</h5>
				<div className="DownloadConfig">
					<div className="DownloadTitleLine">
						<h6>Allow Download</h6>
						<Tooltip
							html={tooltipData}
							position="right"
						>
							<Icon
								icon="info_outlined"
								size={16}
							/>
						</Tooltip>
					</div>
					<Switch
						checked={allowsDownload}
						onSwitch={toggleAllowsDownload}
					/>
				</div>
			</div>
			<div className="ShareLinkContainer">
				<div className="ShareLinkFrame" onClick={copyShareLink}>
					<p className="ShareLink">{shareLink}</p>
					<ShareLinkButton
						showSuccessMessage={showSuccessMessage}
						copyShareLink={copyShareLink}
					/>
				</div>
				<p className="ShareLinkDisclaimer">View-only link. No manage permissions are given.</p>
			</div>
		</div>
	);
};

export default ShareReviewLink;
