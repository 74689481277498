import { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { Line } from 'rc-progress';
import Icon from '@ui/components/Icon/Icon';
import { ERROR, MIDDLE_GREY, PRIMARY_BASE, SECONDARY_DARK, SUCCESS } from '../../../styles/colors';
import AssetDownloadJob from '../../../services/AssetDownloadService/AssetDownloadJob';
import IconButton from '../../UI/IconButton/IconButton';

import './AssetDownloadBoxItem.scss';

type AssetDownloadBoxItemProps = {
	assetDownloadJob: AssetDownloadJob,
	testID?: string
};

const AssetDownloadBoxItem = ( { assetDownloadJob, testID }: AssetDownloadBoxItemProps ) => {
	const cancelIcon = () => (
		<IconButton
			icon="clear"
			size={16}
			color={SECONDARY_DARK}
			onClick={() => assetDownloadJob.cancel()}
			testID={`${testID}-cancel-button`}
		/>
	);

	const canceledIcon = () => <Icon icon="remove_circle" size={16} />;
	const successIcon = () => <Icon icon="check_circle" size={16} color={SUCCESS} />;
	const errorIcon = () => <Icon icon="error" size={16} color={ERROR} />;

	const statusColors = useMemo( () => ( {
		unknown: PRIMARY_BASE,
		zipping: PRIMARY_BASE,
		successful: SUCCESS,
		failed: ERROR,
		canceled: MIDDLE_GREY
	} ), [] );

	const statusIcons = useMemo( () => ( {
		unknown: cancelIcon,
		zipping: cancelIcon,
		successful: successIcon,
		failed: errorIcon,
		canceled: canceledIcon
	} ), [ assetDownloadJob ] );

	return (
		<div className="AssetDownloadBoxItem" data-testid={testID}>
			{statusIcons[ assetDownloadJob.status ]()}
			<div className="data">
				<div className="top-data">
					<p className="title">Zipping Assets</p>
					<p className="zipped-assets-count">{assetDownloadJob.zippedAssetsCountText}</p>
				</div>
				<Line
					className="progress-bar"
					percent={assetDownloadJob.progressPercent}
					strokeWidth={2}
					trailWidth={2}
					trailColor={MIDDLE_GREY}
					strokeColor={statusColors[ assetDownloadJob.status ]}
				/>
			</div>
		</div>
	);
}

export default observer( AssetDownloadBoxItem );
