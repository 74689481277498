import PropTypes from 'prop-types';
import Icon from '@ui/components/Icon/Icon';

import MemberPropType from '../../types/member';

import './ProjectMemberWithRole.scss';
import UserAvatar from '../UserAvatar/UserAvatar';

const ProjectMemberWithRole = ( {
	member, canDelete, onDeleteClick
} ) => (
	<div className="ProjectMemberWithRole">
		<div className="profile-pic">
			<div className="img-container">
				<UserAvatar user={member.user} hideTooltip />
			</div>
		</div>
		<div className="info">
			<h5>{member.user.name}</h5>
			<p>{member.user.email}</p>
		</div>
		<p className="role">
			{member.role.name}
		</p>
		<div className="remove-container">
			{canDelete && (
				<button className="remove" onClick={onDeleteClick}>
					<Icon icon="delete_outlined" size={20} />
				</button>
			)}
		</div>
	</div>
);

ProjectMemberWithRole.propTypes = {
	member: MemberPropType.isRequired,
	canDelete: PropTypes.bool,
	onDeleteClick: PropTypes.func
};

ProjectMemberWithRole.defaultProps = {
	canDelete: false,
	onDeleteClick: () => {}
};

export default ProjectMemberWithRole;
