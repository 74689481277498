import { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import Icon from '@ui/components/Icon/Icon';

import FormInput from '../../new_arch/components/FormInput/FormInput';
import Button from '../../new_arch/components/Button/Button';
import AvatarPicker from '../AvatarPicker/AvatarPicker';
import WhiteBox from '../WhiteBox/WhiteBox';
import EmailVerificationModal from '../../modals/EmailVerificationModal/EmailVerificationModal';

import User from '../../entities/user';
import { createForm } from '../../forms/profileSettings';
import { onFieldBlur, onFieldChange, getValidationErrorMessage } from '../../lib/forms/formUtils';
import { PRIMARY_BASE } from '../../styles/colors';

import './ProfileSettings.scss';

class ProfileSettings extends Component {
	constructor( props ) {
		super( props );

		const { name, email } = this;
		this.state = {
			form: createForm( { name, email, avatar: null } ),
			editMode: false,
			authModalOpened: false
		};

		this.avatarPickerRef = createRef();

		this._onNameChange = onFieldChange.bind( this, 'name' );
		this._onEmailChange = onFieldChange.bind( this, 'email' );
		this._onAvatarChange = onFieldChange.bind( this, 'avatar' );

		this._openImagePicker = this._openImagePicker.bind( this );

		this._onEditInformationClicked = this._onEditInformationClicked.bind( this );
		this._onSaveInformationClicked = this._onSaveInformationClicked.bind( this );
		this._onCancelEditClicked = this._onCancelEditClicked.bind( this );
		this._onCloseModal = this._onCloseModal.bind( this );

		this._onNameBlur = onFieldBlur.bind( this, 'name' );
		this._onEmailBlur = onFieldBlur.bind( this, 'email' );

		this._getNameError = getValidationErrorMessage.bind( this, 'name' );
		this._getEmailError = getValidationErrorMessage.bind( this, 'email' );
	}

	get hasChanges() {
		return !!this.state.editedFields;
	}

	get isValid() { return this.state.form.validate(); }

	get editedData() {
		const { name, email } = this;
		return this.state.form.getOnlyEditedData( { name, email, avatar: null } );
	}

	get avatar() {
		return this.props.currentUser.avatar;
	}

	get name() {
		return this.props.currentUser.name;
	}

	get email() {
		return this.props.currentUser.email;
	}

	get authProvider() {
		return this.props.currentUser.authProvider;
	}

	get cannotEditEmail() {
		return this.props.currentUser.isLinkedToExternalAuthProvider;
	}

	get cannotEditEmailMessage() {
		return `This email is from your ${this.authProvider?.capitalize()} account and cannot be changed here`;
	}

	hasEmailChanged() {
		const currentEmail = this.state.form.data.email;
		const originalEmail = this.email;

		return currentEmail !== originalEmail;
	}

	clear() {
		const { name, email } = this;
		this.setState( { form: createForm( { name, email, avatar: null } ) } );
		const { current: avatarPickerComponent } = this.avatarPickerRef;
		if ( avatarPickerComponent ) { avatarPickerComponent.clear(); }
	}

	_onEditInformationClicked() {
		this.setState( { editMode: true } );
	}

	_onCancelEditClicked() {
		this.clear();
		this.setState( { editMode: false } );
	}

	_onSaveInformationClicked() {
		const { sendEmailVerificationHandler, updateProfileHandler } = this.props;
		const { email } = this.state.form.data;

		if ( this.hasEmailChanged() ) {
			sendEmailVerificationHandler(
				email,
				() => this.setState( { authModalOpened: true } )
			);
		} else {
			updateProfileHandler(
				this.editedData,
				() => this.setState( { editMode: false } )
			);
		}
	}

	_onCloseModal() {
		this.clear();
		this.setState( { authModalOpened: false, editMode: false } );
	}

	_openImagePicker() {
		if ( this.avatarPickerRef && this.avatarPickerRef.current
				&& this.avatarPickerRef.current.inputRef
				&& this.avatarPickerRef.current.inputRef.current ) {
			this.avatarPickerRef.current.inputRef.current.click();
		}
	}

	render() {
		const { name, email } = this.state.form.data;
		const { hasChanges, isValid, avatar } = this;
		const { currentUser } = this.props;

		const isEditModeDisabled = !this.state.editMode;

		return (
			<WhiteBox>
				<div className="ProfileSettings">
					<div className="content">
						<AvatarPicker
							ref={this.avatarPickerRef}
							avatar={avatar}
							onFileChange={this._onAvatarChange}
							disabled={isEditModeDisabled}
						/>
						<Button
							type="text"
							className="changeProfilePicture"
							onClick={this._openImagePicker}
							disabled={isEditModeDisabled}
						>
							Change Profile Picture
						</Button>
						<FormInput
							name="name"
							label="Full Name"
							value={name}
							error={this._getNameError()}
							onChange={this._onNameChange}
							onBlur={this._onNameBlur}
							placeholder="Full Name"
							disabled={isEditModeDisabled}
						/>
						<FormInput
							name="email"
							label="Email"
							value={email}
							error={this._getEmailError()}
							onChange={this._onEmailChange}
							onBlur={this._onEmailBlur}
							placeholder="Email"
							disabled={this.cannotEditEmail || isEditModeDisabled}
						/>
						{this.cannotEditEmail && <p>{this.cannotEditEmailMessage}</p>}
					</div>
					{ this.state.editMode ? (
						<div className="editInformationFooter">
							<Button color="secondary" className="cancelEditButton" onClick={this._onCancelEditClicked}>
								Cancel
							</Button>
							<Button
								disabled={!hasChanges || !isValid}
								className="saveInformationButton"
								onClick={this._onSaveInformationClicked}
							>
								Save
							</Button>
						</div>
					) : (
						<Button className="editInformationButton" type="text" onClick={this._onEditInformationClicked}>
							<Icon icon="edit" color={PRIMARY_BASE} />
							Edit Information
						</Button>
					)}
					{this.state.authModalOpened && (
						<EmailVerificationModal
							onClose={this._onCloseModal}
							userID={currentUser.id}
							data={this.editedData}
						/>
					)}
				</div>
			</WhiteBox>
		);
	}
}

ProfileSettings.propTypes = {
	currentUser: PropTypes.instanceOf( User ).isRequired,
	updateProfileHandler: PropTypes.func,
	sendEmailVerificationHandler: PropTypes.func
};

ProfileSettings.defaultProps = {
	updateProfileHandler: () => {},
	sendEmailVerificationHandler: () => {}
};

export default ProfileSettings;
