import { observer } from 'mobx-react-lite';

import Icon from '@ui/components/Icon/Icon';
import { SECONDARY_DARK } from '../../../styles/colors';
import IconButton from '../../UI/IconButton/IconButton';

import type AssetDownloadService from '../../../services/AssetDownloadService/AssetDownloadService';

import './AssetDownloadBoxHeader.scss';

type AssetDownloadBoxHeaderProps = {
	assetDownloadService: AssetDownloadService,
	isMinimized: boolean,
	onMinimize: () => void,
	onClose: () => void
}

const AssetDownloadBoxHeader = ( {
	assetDownloadService,
	isMinimized,
	onMinimize,
	onClose
}: AssetDownloadBoxHeaderProps ) => {
	const {
		hasUnfinishedDownloads
	} = assetDownloadService;

	return (
		<div className="AssetDownloadBoxHeader">
			<div className="title">
				<Icon icon="cloud_download_outlined" size={16} color={SECONDARY_DARK} />
				{hasUnfinishedDownloads ? <h5>Preparing Download</h5> : <h5>Download Completed</h5>}
			</div>
			<div className="box-control">
				<IconButton
					icon={isMinimized ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
					size={24}
					color={SECONDARY_DARK}
					onClick={onMinimize}
					testID="minimize-button"
				/>
				{!hasUnfinishedDownloads && (
					<IconButton
						icon="close"
						size={18}
						color={SECONDARY_DARK}
						onClick={onClose}
						testID="close-button"
					/>
				)}
			</div>
		</div>
	);
}

export default observer( AssetDownloadBoxHeader );
