import { isValidElement } from 'react';
import PropTypes from 'prop-types';
import { components } from 'react-select';
import classNames from 'classnames';
import Icon from '@ui/components/Icon/Icon';
import { PRIMARY_BASE } from '../../../../styles/colors';

const Option = ( props ) => {
	const {
		data: {
			description, caption, isButtonOption, customComponent, wrapperStyle, greyOut, isDisabled
		}, isSelected, label, withDescription
	} = props;

	const renderDefaultOption = () => (
		<>
			{isButtonOption
				? (
					<div className="buttonOption">
						<Icon icon="add" size="20" />
						<p>{label}</p>
					</div>
				)
				: (
					<p className="name">
						{label}
						{' '}
						<span className="caption">{caption}</span>
					</p>
				)}
			{description && <p className="description">{description}</p>}
		</>
	);

	return (
		<div
			className={classNames( 'option-wrapper', { withDescription, greyOut: greyOut || isDisabled } )}
			style={wrapperStyle}
		>
			<components.Option {...props}>
				{isValidElement( customComponent ) ? customComponent : renderDefaultOption()}
				{isSelected && <Icon icon="check" size="20" color={PRIMARY_BASE} />}
			</components.Option>
		</div>
	);
};

Option.propTypes = {
	onChange: PropTypes.func,
	withDescription: PropTypes.bool,
	description: PropTypes.string,
	isSelected: PropTypes.bool,
	label: PropTypes.string,
	data: PropTypes.any,
	isButtonOption: PropTypes.bool,
	greyOut: PropTypes.bool,
	isDisabled: PropTypes.bool,
	customComponent: PropTypes.node,
	wrapperStyle: PropTypes.any
};

Option.defaultProps = {
	onChange: () => {},
	withDescription: false,
	description: undefined,
	isSelected: false,
	label: undefined,
	data: undefined,
	isButtonOption: false,
	greyOut: false,
	isDisabled: false,
	customComponent: undefined,
	wrapperStyle: undefined
};

export default Option;
