import classNames from 'classnames';
import Icon from '@ui/components/Icon/Icon';

import Button from '../../new_arch/components/Button/Button';
import { SUCCESS_700, PRIMARY_BASE, DARK_GREY } from '../../styles/colors';
import './ShareLinkButton.scss';

export type ShareLinkButtonProps = {
	showSuccessMessage: boolean,
	copyShareLink: () => void,
	disabled?: boolean
};

const ShareLinkButton = ( { showSuccessMessage, copyShareLink, disabled }: ShareLinkButtonProps ) => {
	const activeButtonColor = showSuccessMessage ? SUCCESS_700 : PRIMARY_BASE;

	return (
		<div className="ShareLinkButton">
			<Button className={classNames( { showSuccessMessage, disabled } )} onClick={copyShareLink} type="text" disabled={disabled}>
				<Icon
					className="CopyLinkIcon"
					color={disabled ? DARK_GREY : activeButtonColor}
					icon="link"
					size={20}
				/>
				{showSuccessMessage ? 'Copied!' : 'Copy Link'}
			</Button>
		</div>
	);
};

export default ShareLinkButton;
