import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SnackbarSystem from '@ui/systems/SnackbarSystem';

import actionsCreator from '../actions';
import reduxSystem from '../systems';

const bindReduxSystems = dispatch => Object.keys( reduxSystem ).reduce(
	( systemsWithDispatch, systemName ) => ( {
		...systemsWithDispatch,
		[ systemName ]: new reduxSystem[ systemName ]( dispatch )
	} ),
	{}
);

const mapDispatchToProps = dispatch => ( {
	...bindReduxSystems( dispatch ),
	...bindActionCreators( actionsCreator, dispatch ),
	snackbarSystem: SnackbarSystem.shared()
} );

export const connectComponent = ( mapStateToProps, forwardRef ) => connect(
	mapStateToProps, mapDispatchToProps, null, { forwardRef }
);
