import PropTypes from 'prop-types';
import Icon from '@ui/components/Icon/Icon';
import { ERROR } from '../../styles/colors';
import Button from '../../new_arch/components/Button/Button';

import './ReactivateWorkspaceBox.scss';

const ReactivateWorkspaceBox = ( { onSeePlansClick } ) => (
	<div className="ReactivateWorkspaceBox">
		<Icon
			icon="error"
			size={16}
			color={ERROR}
		/>
		<h6>To reactivate your workspace, please select a plan</h6>
		<Button size="large" onClick={onSeePlansClick}>See Plans</Button>
	</div>
);

ReactivateWorkspaceBox.propTypes = {
	onSeePlansClick: PropTypes.func.isRequired
};

export default ReactivateWorkspaceBox;
