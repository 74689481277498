import Icon from '@ui/components/Icon/Icon';
import Tooltip from '../UI/Tooltip/Tooltip';
import { DARK_GREY, WHITE } from '../../styles/colors';
import './ContactGroupsTooltip.scss';

const ContactGroupsTooltip = ( ) => {
	const tooltipData = (
		<div className="tippy-tooltip-content" style={{ textAlign: 'left', fontSize: '10px', width: '200px' }}>
			<p className="tooltip-text" style={{ color: WHITE }}>
				Create groups of collaborators to quickly share projects with multiple people.
				<span style={{ fontWeight: 'bold', color: WHITE }}>
					<br />Editing or deleting&nbsp;
				</span>
				them won&apos;t affect current projects.
			</p>
		</div>
	);

	return (
		<div className="ContactGroupsTooltip">
			<Tooltip
				className="contact-groups-tooltip"
				html={tooltipData}
				position="right"
			>
				<div data-testId="tooltipIcon">
					<Icon
						icon="info_outlined"
						size={16}
						color={DARK_GREY}
					/>
				</div>
			</Tooltip>
		</div>
	);
};

export default ContactGroupsTooltip;
