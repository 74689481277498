import { Component } from 'react';
import PropTypes from 'prop-types';
import SnackbarSystem from '@ui/systems/SnackbarSystem';
import { handleAsyncActionResult } from '../lib/reduxUtils';

export default class Container extends Component {
	handleAsyncActionResult(
		asyncActionResult,
		successTexts,
		errorTexts,
		resetAction,
		onSuccess,
		onError
	) {
		const { snackbarSystem } = this.props;

		return handleAsyncActionResult(
			asyncActionResult,
			successTexts,
			errorTexts,
			resetAction,
			snackbarSystem.showSuccessMessage,
			snackbarSystem.showErrorMessage,
			onSuccess,
			onError
		);
	}

	showAsyncActionConfirmation(
		text,
		acceptText,
		cancelText,
		asyncAction,
		description = null
	) {
		const { showDefaultConfirmationDialog, hideConfirmationDialog } = this.props;

		showDefaultConfirmationDialog( text, description, acceptText, cancelText )
			.then( ( wasConfirmed ) => {
				if ( !wasConfirmed ) { return; }

				asyncAction()
					.then( () => hideConfirmationDialog() );
			} );
	}

	showActionConfirmation(
		action,
		text,
		acceptText = 'Ok',
		cancelText = 'Cancel'
	) {
		const { showDefaultConfirmationDialog, hideConfirmationDialog } = this.props;

		showDefaultConfirmationDialog( text, null, acceptText, cancelText )
			.then( ( wasConfirmed ) => {
				if ( !wasConfirmed ) { return; }

				action();
				hideConfirmationDialog();
			} );
	}
}

Container.propTypes = {
	snackbarSystem: PropTypes.instanceOf( SnackbarSystem ),
	showDefaultConfirmationDialog: PropTypes.func,
	hideConfirmationDialog: PropTypes.func
};

Container.defaultProps = {
	showDefaultConfirmationDialog: () => {},
	hideConfirmationDialog: () => {}
};
