import { useCallback, CSSProperties, memo, useRef } from 'react';

import Notification from '@notifications/models/Notification';
import NotificationFilter from '@notifications/models/NotificationFilter';
import { VariableSizeList } from '@virtualization/List';

import useNotificationsList from './useNotificationsList';
import NotificationListItem, { ESTIMATED_ROW_HEIGHT } from './NotificationListItem/NotificationListItem';
import EmptyState from './EmptyState/EmptyState';
import NotificationSkeleton from './NotificationSkeleton/NotificationSkeleton';

import './NotificationsList.scss';

type NotificationsListProps = {
	projectID: number;
	selectedFilter: NotificationFilter,
	onResetFiltersClick?: () => void;
};

const NotificationsList = ( {
	projectID,
	selectedFilter,
	onResetFiltersClick
}: NotificationsListProps ) => {
	const {
		notifications,
		isFiltering,
		isLoading,
		isFetchingNextPage,
		itemHeight,
		onScrollEndReached,
		itemKeyExtractor,
		onNotificationClicked,
		onNotificationProjectClicked,
		onNotificationFolderClicked
	} = useNotificationsList( { projectID, selectedFilter } );

	let loaderCount = isLoading ? 3 : 0;
	if ( isFetchingNextPage ) loaderCount = 1;

	const scrollableContainerRef = useRef<HTMLDivElement | null>( null );

	const renderItem = useCallback( ( item: Notification, rowStyle: CSSProperties ) => (
		<NotificationListItem
			notification={item}
			style={rowStyle}
			onNotificationClicked={onNotificationClicked}
			onNotificationProjectClicked={onNotificationProjectClicked}
			onNotificationFolderClicked={onNotificationFolderClicked}
		/>
	), [ onNotificationClicked, onNotificationProjectClicked, onNotificationFolderClicked ] );

	const renderLoader = useCallback( ( cellStyle: CSSProperties ) => (
		<NotificationSkeleton style={cellStyle} />
	), [] );

	return (
		<div className="NotificationsList">
			{notifications?.length === 0 ? (
				<EmptyState isFiltering={isFiltering} onResetFiltersClick={onResetFiltersClick} />
			) : (
				<VariableSizeList
					items={notifications || []}
					renderItem={renderItem}
					renderLoader={renderLoader}
					loaderCount={loaderCount}
					estimatedRowHeight={ESTIMATED_ROW_HEIGHT}
					itemHeight={itemHeight}
					itemKeyExtractor={itemKeyExtractor}
					onScrollEndReached={onScrollEndReached}
					scrollableContainerRef={scrollableContainerRef}
				/>
			)}
		</div>
	);
};

export default memo( NotificationsList );
