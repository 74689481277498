import { useMemo } from 'react';
import Icon from '@ui/components/Icon/Icon';

import IconButton from '../../components/UI/IconButton/IconButton';
import Button from '../../new_arch/components/Button/Button';
import { STRONG_ERROR } from '../../styles/colors';
import CopyTextButton from './components/CopyTextButton';
import './IncompleteFolderUploadPopup.scss';

type IncompleteFolderUploadPopupProps = {
	failedUploads: Array<{ id: number, path: string }>,
	onCancel: () => void,
	onConfirm: () => void
}

const IncompleteFolderUploadPopup = ( {
	failedUploads, onCancel, onConfirm
}: IncompleteFolderUploadPopupProps ) => {
	const textToCopy = useMemo(
		() => failedUploads.map( upload => upload.path ).join( '\n' ),
		[ failedUploads ]
	);

	return (
		<div className="IncompleteFolderUploadPopup">
			<div className="dialog">
				<IconButton
					icon="clear"
					buttonClassName="close-icon"
					onClick={onCancel}
					size={24}
					testID="close-icon"
				/>
				<Icon
					className="error-icon"
					icon="error"
					size={20}
					color={STRONG_ERROR}
				/>
				<h4>Incomplete Folder Upload</h4>
				<div className="description-container">
					<p className="description">
						{'Sorry, something unexpected happened, and not all files were successfully uploaded. '}
						<span>
							Please try uploading the files again. If the issue persists, check your internet connection.
						</span>
					</p>
					<div className="failed-uploads-container">
						<p className="title">Files Not Uploaded</p>
						<div className="failed-upload-paths">
							{failedUploads.map( upload => <span key={upload.id}>{upload.path}</span> )}
						</div>
					</div>
					<CopyTextButton text={textToCopy} className="copy-text-button" />
				</div>
				<Button
					type="filled"
					color="primary"
					onClick={onConfirm}
				>
					Got it
				</Button>
			</div>
		</div>
	);
}

export default IncompleteFolderUploadPopup;
