import React from 'react';
import classNames from 'classnames';
import Icon from '@ui/components/Icon/Icon';

import IconButton from '../../../../../../components/UI/IconButton/IconButton';

import { type Node } from '../../../../../components/TreeView';

import './FolderTreeNode.scss';

type FolderTreeNodeProps = {
	treeViewNode: Node,
	selected: boolean,
	onClick: ( folderID: number ) => void,
	rightCaption: string | null
};

const FolderTreeNode = ( { treeViewNode, selected, onClick, rightCaption }: FolderTreeNodeProps ) => {
	const { id, name, hasChildren, open } = treeViewNode;
	const arrowIcon = open ? 'keyboard_arrow_down' : 'keyboard_arrow_right';
	const onArrowIconClicked = ( event: React.MouseEvent<HTMLButtonElement, MouseEvent> ) => {
		event.stopPropagation();
		treeViewNode.toggleOpen();
	}

	return (
		<div
			className={classNames( 'FolderTreeNode', { hasChildren, open, selected } )}
			onClick={() => onClick( id )}
		>
			<div className="left-section">
				<IconButton
					icon={arrowIcon}
					size={16}
					buttonClassName="arrow-icon"
					onClick={onArrowIconClicked}
					testID={`folder-${id}-arrow-icon`}
				/>
				<Icon icon="folder-outlined" size={16} className="folder-icon" />
				<span className="folder-name">{name}</span>
			</div>
			{rightCaption && <span className="right-caption">{rightCaption}</span>}
		</div>
	);
}

export default FolderTreeNode;
