import { useState } from 'react';
import { useDispatch } from 'react-redux';

import EmailVerificationModalPresenter, { UserProfile } from './EmailVerificationModalPresenter';
import { sendProfileVerificationEmail, editProfile } from '../../actions/users';
import { ErrorResponse } from '../../types/ErrorResponse';
import { EmailVerificationModalProps }
	from '../../modals/EmailVerificationModal/EmailVerificationModal';

const useEmailVerificationModalPresenter = ( {
	onClose,
	data,
	userID
}: EmailVerificationModalProps ) => {
	const dispatch = useDispatch();
	const sendVerificationEmail = ( email: string ) => (
		dispatch( sendProfileVerificationEmail( email ) ) as unknown as Promise<ErrorResponse>
	);
	const updateProfile = ( id: number, profileData: UserProfile, accessCode: string ) => (
		dispatch( editProfile( id, profileData, accessCode ) ) as unknown as Promise<ErrorResponse>
	);

	const createPresenter = () => new EmailVerificationModalPresenter( {
		onClose,
		sendVerificationEmail,
		updateProfile,
		data,
		userID
	} );

	const [ presenter ] = useState( createPresenter );

	return presenter;
}

export default useEmailVerificationModalPresenter;
