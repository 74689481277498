import { Anchor, Badge, Group, Text, Title } from '@mantine/core';
import Icon from '@ui/components/Icon/Icon';

const Header = () => (
	<Group p={16} spacing={12} position="apart">
		<Group spacing={12}>
			<Title order={4}>Similar Names Found</Title>
			<Badge variant="outline">Beta Feature</Badge>
		</Group>
		<Anchor href="https://feedback.verybusy.io/b/7vzlxrmy/feature-ideas/advanced-uploads" target="_blank" color="gray.4">
			<Group spacing={9}>
				<Text variant="footnote-semibold">Leave Feedback</Text>
				<Icon icon="auto-fix-high" size={18} color="gray.4" />
			</Group>
		</Anchor>
	</Group>
);

export default Header;
