import { DefaultProps, Selectors, MantineColor, useMantineTheme, MantineSize } from '@mantine/core';
import MaterialIcon, { MaterialIconName } from './MaterialIcon/MaterialIcon';
import SvgIcon, { isSvgIcon, SvgIconName } from './SvgIcon/SvgIcon';

import useStyles, { IconStylesParams } from './Icon.styles';

type IconStylesNames = Selectors<typeof useStyles>;

export type IconName = SvgIconName | MaterialIconName;
export interface IconProps extends DefaultProps<IconStylesNames, IconStylesParams> {
	icon: IconName,
	color?: MantineColor,
	size?: MantineSize | number
}

type PredefinedSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl';
const isPredefinedSize = ( size: unknown ): size is PredefinedSize => (
	[ 'xs', 'sm', 'md', 'lg', 'xl' ].includes( size as string )
);
const ICON_SIZES: Record<MantineSize, number> = {
	xs: 12,
	sm: 16,
	md: 20,
	lg: 24,
	xl: 32
}

const Icon = ( {
	icon,
	color: colorProp,
	size: sizeProp = 'md',
	classNames,
	styles,
	unstyled,
	className,
	...others
}: IconProps ) => {
	const theme = useMantineTheme();

	const color = colorProp ? theme.fn.themeColor( colorProp, undefined, false, false ) : undefined;
	const size = isPredefinedSize( sizeProp )
		? ICON_SIZES[ sizeProp ]
		: sizeProp;

	const { classes, cx } = useStyles(
		{ color },
		{ name: 'Icon', classNames, styles, unstyled }
	);

	const IconComponent = isSvgIcon( icon ) ? SvgIcon : MaterialIcon;

	return (
		<IconComponent
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			icon={icon}
			color={color}
			size={size}
			className={cx( classes.root, className )}
			{...others}
		/>
	);
};

export default Icon;
