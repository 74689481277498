import Icon from '@ui/components/Icon/Icon';
import { MaterialIconName } from '@ui/components/Icon/MaterialIcon/MaterialIcon';

import FreeAccountPushForGuestsButton
	from '../../new_arch/modules/noAccountCollaboration/FreeAccountPushForGuestsButton/FreeAccountPushForGuestsButton';
import { PRIMARY_BASE } from '../../styles/colors';

import './FreeAccountPushForGuests.scss';

const FreeAccountPushForGuests = () => {
	const featuresToShow: Array<{ icon: MaterialIconName, text: string }> = [
		{
			icon: 'person-outlined',
			text: 'Control over your account'
		},
		{
			icon: 'apps-outlined',
			text: 'Oversee all your projects'
		},
		{
			icon: 'notifications-outlined',
			text: 'Customize notifications & alerts'
		},
		{
			icon: 'add-circle-outlined',
			text: 'Create your own projects'
		}
	];

	return (
		<div className="FreeAccountPushForGuests">
			<p className="title">Unlock all features</p>

			<div className="features">
				{featuresToShow.map( feature => (
					<div className="feature" key={feature.icon}>
						<div className="icon">
							<Icon icon={feature.icon} size={16} color={PRIMARY_BASE} />
						</div>
						<div className="text">
							{feature.text}
						</div>
					</div>
				) )}
			</div>

			<div className="footer">
				<FreeAccountPushForGuestsButton />
			</div>
		</div>
	)
}

export default FreeAccountPushForGuests;
