import { useState } from 'react';
import classNames from 'classnames';
import Icon from '@ui/components/Icon/Icon';

import './LabelsColorsGrid.scss';
import { LABEL_COLORS, DEFAULT_LABEL_COLOR } from '../../types/labelColor';

interface LabelsColorsGridProps {
	initialColor?: { baseColor: string, hoverColor: string };
	onColorClick?: ( color: { name: string, baseColor: string, hoverColor: string } ) => void;
	asRow?: boolean;
}

const LabelsColorsGrid = ( { initialColor, onColorClick, asRow } : LabelsColorsGridProps ) => {
	const [ hoveredColor, setHoveredColor ] = useState<string | null>( null );
	const [
		selectedColor, setSelectedColor
	] = useState<{ name: string, baseColor: string, hoverColor: string } | null>(
		LABEL_COLORS.find( color => (
			color.baseColor === initialColor?.baseColor
				&& color.hoverColor === initialColor?.hoverColor ) )
		|| DEFAULT_LABEL_COLOR
	);

	const isSelected = ( color: string ) => selectedColor?.name === color;

	const handleColorClick = ( color: { name: string, baseColor: string, hoverColor: string } ) => {
		setSelectedColor( color );
		if ( onColorClick ) onColorClick( color );
	}

	const renderColor = (
		{ name, baseColor, hoverColor } : { name: string, baseColor: string, hoverColor: string }
	) => {
		const res = isSelected( name )
			? (
				<div
					key={name}
					className="color selected"
					style={{
						backgroundColor: hoveredColor === name ? hoverColor : baseColor,
						borderColor: hoverColor
					}}
					onMouseEnter={() => setHoveredColor( name )}
					onMouseLeave={() => setHoveredColor( null )}
					onClick={() => handleColorClick( { name, baseColor, hoverColor } )}
				>
					<Icon icon="check" size={16} color="black" />
				</div>
			)
			: (
				<div
					key={name}
					className="color"
					style={{ backgroundColor: hoveredColor === name ? hoverColor : baseColor }}
					onMouseEnter={() => setHoveredColor( name )}
					onMouseLeave={() => setHoveredColor( null )}
					onClick={() => handleColorClick( { name, baseColor, hoverColor } )}
				/>
			);
		return res;
	}

	return (
		<div className={classNames( 'LabelsColorsGrid', { asRow } )} data-testid="labels-colors-grid">
			{
				LABEL_COLORS.map(
					( { name, baseColor, hoverColor } ) => renderColor( { name, baseColor, hoverColor } )
				)
			}
		</div>
	);
}

export default LabelsColorsGrid;
