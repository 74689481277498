import { forwardRef, useImperativeHandle } from 'react';
import { DefaultProps, Selectors, Stack, UnstyledButton, Collapse } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import Icon from '@ui/components/Icon/Icon';

import useStyles, { ExpandableStylesParams } from './Expandable.styles';

type ExpandableStylesNames = Selectors<typeof useStyles>;

export interface ExpandableProps extends DefaultProps<ExpandableStylesNames, ExpandableStylesParams> {
	title: string;
	children: React.ReactNode;
	initiallyOpened?: boolean;
	onOpen?: () => void;
	onClose?: () => void;
}

export interface ExpandableRef {
	open: () => void;
	close: () => void;
	toggle: () => void;
}

const Expandable = forwardRef<ExpandableRef, ExpandableProps>( ( {
	title,
	children,
	onOpen,
	onClose,
	initiallyOpened = false,
	classNames,
	styles,
	unstyled,
	className,
	...others
}, ref ) => {
	const [ opened, { toggle, open, close } ] = useDisclosure( initiallyOpened, { onOpen, onClose } );

	const { classes, cx } = useStyles(
		{ opened },
		{ name: 'Expandable', classNames, styles, unstyled }
	);

	useImperativeHandle( ref, () => ( { open, close, toggle } ) );

	return (
		<Stack className={cx( classes.root, className )} spacing={0} {...others}>
			<UnstyledButton className={classes.trigger} onClick={toggle}>
				<span className={classes.title}>{title}</span>
				<Icon icon={opened ? 'expand_less' : 'expand_more'} className={classes.icon} />
			</UnstyledButton>
			<Collapse in={opened}>{children}</Collapse>
		</Stack>
	);
} );

export default Expandable;
