import { MantineThemeOverride } from '@mantine/core';

import colors from './tokens/colors/colors';
import { headingsSizes } from './tokens/typography/typography';

import ButtonDefaultConfig from './components/Button/config';
import ProgressDefaultConfig from './components/Progress/config';
import BadgeDefaultConfig from './components/Badge/config';
import TooltipDefaultConfig from './components/Tootlip/config';
import ListDefaultConfig from './components/List/config';
import CheckboxDefaultConfig from './components/Checkbox/config';
import NotificationDefaultConfig from './components/Notification/config';

export default {
	// Colors
	colorScheme: 'light',
	colors,
	primaryColor: 'primary',
	primaryShade: 5,

	// Fonts
	fontFamily: 'Open Sans, sans-serif',
	components: {
		Button: ButtonDefaultConfig,
		Progress: ProgressDefaultConfig,
		Badge: BadgeDefaultConfig,
		Tooltip: TooltipDefaultConfig,
		List: ListDefaultConfig,
		Checkbox: CheckboxDefaultConfig,
		Notification: NotificationDefaultConfig
	},

	// Headings
	headings: {
		fontFamily: 'Open Sans, sans-serif',
		sizes: headingsSizes
	}
} as MantineThemeOverride;
