import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import useSnackbarSystem from '@ui/systems/useSnackbarSystem';

import useAutoupdatePresenterProperty from '../../hooks/useAutoupdatePresenterProperty';
import MembersUsagePresenter from './MembersUsagePresenter';
import PopupSystem from '../../systems/PopupSystem';
import AddOnSystem from '../../systems/AddOnSystem';
import { getAddOns } from '../../selectors/addOns';

const useMembersUsagePresenter = ( { workspace, onPaidFeatureModalAcceptClick } ) => {
	const dispatch = useDispatch();
	const addOns = useSelector( getAddOns );
	const history = useHistory();
	const snackbarSystem = useSnackbarSystem();

	const createPresenter = () => {
		const popupSystem = new PopupSystem( dispatch );
		const addOnSystem = new AddOnSystem( dispatch );

		return new MembersUsagePresenter( {
			popupSystem,
			addOnSystem,
			snackbarSystem,
			workspace,
			addOns,
			history,
			onPaidFeatureModalAcceptClick
		} );
	};

	const [ presenter ] = useState( createPresenter );

	useAutoupdatePresenterProperty( { presenter, name: 'workspace', value: workspace } );
	useAutoupdatePresenterProperty( { presenter, name: 'addOns', value: addOns } );
	useAutoupdatePresenterProperty( { presenter: presenter.addOnsModalPresenter, name: 'workspace', value: workspace } );
	useAutoupdatePresenterProperty( { presenter: presenter.addOnsModalPresenter, name: 'addOns', value: addOns } );

	return presenter;
};

export default useMembersUsagePresenter;
