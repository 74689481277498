import { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import SnackbarSystem from '@ui/systems/SnackbarSystem';

import { connectComponent } from '../../lib/connectComponent';
import ProjectRole from '../../entities/projectRole';
import { getSortedProjectMembers, getCurrentUserRoleForProject, getSortedProjectInvitations, getProject } from '../../selectors/projects';
import { actionResultIsError } from '../../lib/reduxUtils';
import AsyncActionStatePropType, { defaultProp as defaultAsyncActionState } from '../../types/asyncApiAction';

import ProjectMember from '../../entities/projectMember';
import ProjectInvitation from '../../entities/projectInvitation';

import InviteProjectMemberBox from '../../components/InviteProjectMemberBox/InviteProjectMemberBox';
import { getUserCanInviteProjectMembers } from '../../selectors/permissions/usersPermissions';

import { multipleInvitationsSuccessMessage } from '../../messages/projectInvitation';
import { messageForError } from '../../errors/projectInvitation';
import Project from '../../entities/project';

export class InviteProjectMemberBoxContainer extends Component {
	constructor( props ) {
		super( props );

		this.inviteProjectMemberBox = createRef();

		this._onInviteMembersClicked = this._onInviteMembersClicked.bind( this );
	}

	componentDidMount() {
		const { fetchProjectMembers, fetchProjectInvitations, projectID } = this.props;
		fetchProjectMembers( projectID );
		fetchProjectInvitations( projectID );
	}

	get isSendingInvitation() { return this.props.inviteProjectMemberRequest.sending; }

	inviteMembersToProject( inviteData ) {
		const {
			projectID, inviteMembersToProject, fetchProjectMembers, fetchProjectInvitations
		} = this.props;
		const { emails, role, message } = inviteData;
		const { snackbarSystem } = this.props;

		inviteMembersToProject( projectID, emails, role, message )
			.then( ( result ) => {
				if ( actionResultIsError( result ) ) {
					throw result.payload;
				}

				snackbarSystem.showSuccessMessage( multipleInvitationsSuccessMessage );
				fetchProjectMembers( projectID );
				fetchProjectInvitations( projectID );

				if ( this.inviteProjectMemberBox.current ) {
					this.inviteProjectMemberBox.current.clearInviteMemberBox();
				}
			} )
			.catch( ( result ) => {
				const errorMessage = messageForError( result )
				return snackbarSystem.showErrorMessage( errorMessage );
			} );
	}

	// Events handlers
	_onInviteMembersClicked( inviteData ) {
		this.inviteMembersToProject( inviteData );
	}

	render() {
		const {
			projectID, currentUserRole, members, pendingInvites, currentUserCanManageMembers,
			onClose, showCloseButton, currentUserCanInviteProjectMembers, useAlternateLayout,
			projectMemberRoleName, project, updateGuestHashEnabled, currentUserCanShareGuestLink
		} = this.props;
		const { inviteProjectMemberBox } = this;

		return (
			<InviteProjectMemberBox
				projectID={projectID}
				ref={inviteProjectMemberBox}
				currentUserRole={currentUserRole}
				members={members}
				pendingInvites={pendingInvites}
				sendingInvitation={this.isSendingInvitation}
				readOnly={!currentUserCanManageMembers}
				onInviteClick={this._onInviteMembersClicked}
				onCloseClick={onClose}
				showCloseButton={showCloseButton}
				membersCanBeInvited={currentUserCanInviteProjectMembers}
				useAlternateLayout={useAlternateLayout}
				projectMemberRoleName={projectMemberRoleName}
				project={project}
				updateGuestHashEnabled={updateGuestHashEnabled}
				currentUserCanShareGuestLink={currentUserCanShareGuestLink}
			/>
		);
	}
}

InviteProjectMemberBoxContainer.propTypes = {
	projectID: PropTypes.number.isRequired,
	members: PropTypes.arrayOf( PropTypes.instanceOf( ProjectMember ) ),
	pendingInvites: PropTypes.arrayOf( PropTypes.instanceOf( ProjectInvitation ) ),
	currentUserRole: PropTypes.instanceOf( ProjectRole ).isRequired,
	inviteProjectMemberRequest: AsyncActionStatePropType,
	currentUserCanManageMembers: PropTypes.bool,
	inviteMembersToProject: PropTypes.func,
	fetchProjectMembers: PropTypes.func,
	fetchProjectInvitations: PropTypes.func,
	onClose: PropTypes.func,
	showSuccessMessage: PropTypes.func,
	showErrorMessage: PropTypes.func,
	showCloseButton: PropTypes.bool,
	currentUserCanInviteProjectMembers: PropTypes.bool,
	useAlternateLayout: PropTypes.bool,
	projectMemberRoleName: PropTypes.string.isRequired,
	project: PropTypes.instanceOf( Project ),
	updateGuestHashEnabled: PropTypes.func,
	currentUserCanShareGuestLink: PropTypes.bool,
	snackbarSystem: PropTypes.instanceOf( SnackbarSystem )
};

InviteProjectMemberBoxContainer.defaultProps = {
	members: [],
	pendingInvites: [],
	currentUserCanManageMembers: false,
	inviteProjectMemberRequest: defaultAsyncActionState,
	inviteMembersToProject: () => {},
	fetchProjectMembers: () => {},
	fetchProjectInvitations: () => {},
	onClose: () => {},
	showSuccessMessage: () => {},
	showErrorMessage: () => {},
	showCloseButton: true,
	currentUserCanInviteProjectMembers: false,
	useAlternateLayout: false,
	project: undefined,
	updateGuestHashEnabled: () => {},
	currentUserCanShareGuestLink: false
};

export default connectComponent( ( state, props ) => ( {
	members: getSortedProjectMembers( state, props ),
	pendingInvites: getSortedProjectInvitations( state, props ),
	currentUserRole: getCurrentUserRoleForProject( state, props ),
	inviteProjectMemberRequest: state.inviteProjectMemberRequest,
	currentUserCanInviteProjectMembers: getUserCanInviteProjectMembers( state, props ),
	project: getProject( state, props )
} ) )( InviteProjectMemberBoxContainer );
