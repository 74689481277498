import { useHistory } from 'react-router';
import { isMobile } from 'src/lib/isMobile';
import useFetchCurrentUserQuery from '../../authentication/queries/useFetchCurrentUserQuery';

const useNavigationPanelIsAvailable = () => {
	const { location: { pathname } } = useHistory();
	const { data: currentUser } = useFetchCurrentUserQuery();

	const onProjectDashboard = pathname === '/projects';
	const onHiddenProjects = pathname === '/hidden_projects';
	const onProjectView = pathname.startsWith( '/projects' ) && !pathname.includes( 'assets' );

	const isOnProjectsPaths = onProjectDashboard || onHiddenProjects || onProjectView;
	const isNotGuest = currentUser && !currentUser.isGuest;

	return isOnProjectsPaths && isNotGuest && !isMobile;
}

export default useNavigationPanelIsAvailable;
