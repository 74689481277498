import PropTypes from 'prop-types';

import Icon from '@ui/components/Icon/Icon';
import Button from '../../../new_arch/components/Button/Button';
import ProgressCardInfo from '../ProgressCardInfo/ProgressCardInfo';

import './ProgressCard.scss';

const ProgressCard = ( {
	icon,
	valueCount,
	maxValue,
	greyOut,
	title,
	unit,
	button,
	maxedOutMultiplier,
	bytesFormat,
	hasAddOns
} ) => (
	<div className="ProgressCard">
		<ProgressCardInfo
			icon={icon}
			valueCount={valueCount}
			maxValue={maxValue}
			title={title}
			unit={unit}
			greyOut={greyOut}
			maxedOutMultiplier={maxedOutMultiplier}
			bytesFormat={bytesFormat}
			hasAddOns={hasAddOns}
		/>
		{button
			&& (
				<div className="button-side">
					{button}
				</div>
			)}
	</div>
);

ProgressCard.propTypes = {
	icon: PropTypes.instanceOf( Icon ).isRequired,
	valueCount: PropTypes.number.isRequired,
	maxValue: PropTypes.number.isRequired,
	greyOut: PropTypes.bool.isRequired,
	title: PropTypes.string.isRequired,
	unit: PropTypes.string,
	button: PropTypes.instanceOf( Button ),
	maxedOutMultiplier: PropTypes.number,
	bytesFormat: PropTypes.bool,
	hasAddOns: PropTypes.bool
};

ProgressCard.defaultProps = {
	button: undefined,
	unit: undefined,
	maxedOutMultiplier: undefined,
	bytesFormat: false,
	hasAddOns: false
};

export default ProgressCard;
