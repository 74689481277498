import PropTypes from 'prop-types';

import CloseableModalWithTitle from '../CloseableModalWithTitle/CloseableModalWithTitle';

import { connectComponent } from '../../lib/connectComponent';

import BaseModal from '../BaseModal';

import AsyncRequestType, { defaultProp as defaultAsyncRequest } from '../../types/asyncApiAction';

import './CreateProjectModal.scss';
import CreateProjectForm from '../../components/projects/CreateProjectForm/CreateProjectForm';
import { isFreeTrialLimitError, isSubscriptionLimitError } from '../../errors/projects';
import { isNotAllowedError } from '../../errors/common';
import { ERROR_NAME_NOT_ALLOWED, ERROR_DEFAULT } from '../../messages/projects';
import { getCurrentUserWorkspacesWithProjectCreationPermissions, getUserOwnsAWorkspace } from '../../selectors/workspaces';
import Workspace from '../../entities/workspace';
import { trackNavigatedToCreateProjectModal } from '../../services/tracker/trackers/navigation';

export class CreateProjectModal extends BaseModal {
	constructor( props ) {
		super( props );

		this._onCloseClicked = this._onCloseClicked.bind( this );
		this._onFormSubmitted = this._onFormSubmitted.bind( this );
	}

	componentDidMount() {
		super.componentDidMount();
		trackNavigatedToCreateProjectModal();
	}

	get isCreatingProject() { return this.props.createProjectRequest.sending; }

	get skipWorkspaceSelection() {
		const { workspaces, currentUserOwnsAWorkspace } = this.props;
		return workspaces.length === 1 && currentUserOwnsAWorkspace;
	}

	// Handlers
	_onCloseClicked() {
		const { isCreatingProject, props: { onClose } } = this;
		if ( !onClose || isCreatingProject ) { return; }
		onClose();
	}

	_onFormSubmitted( properties ) {
		return this._createProject( properties );
	}

	_createProject( properties ) {
		const {
			createProject, resetCreateProjectRequest, onProjectCreationSuccess
		} = this.props;
		if ( !createProject ) { return; }

		const createdProjectTexts = {
			title: 'Project created successfully'
		};

		this.handleAsyncActionResult(
			createProject( properties ),
			createdProjectTexts,
			null,
			resetCreateProjectRequest,
			result => onProjectCreationSuccess( result.id ),
			result => this._showErrorMessage( result )
		);
	}

	_showErrorMessage( { payload } ) {
		const { snackbarSystem } = this.props;

		if ( isSubscriptionLimitError( payload ) || isFreeTrialLimitError( payload ) ) {
			return this.props.onProjectCreationFailed();
		}
		const message = isNotAllowedError( payload )
			? ERROR_NAME_NOT_ALLOWED
			: ERROR_DEFAULT;
		return snackbarSystem.showErrorMessage( { title: message } );
	}

	// Render
	render() {
		const {
			isCreatingProject, skipWorkspaceSelection,
			props: {
				workspaces, topSuccessMessage, initialWorkspaceID
			}
		} = this;

		return (
			<CloseableModalWithTitle
				headerBorderType="small"
				onClose={this._onCloseClicked}
				name="create-project"
				title="Create New Project"
			>
				<div className="CreateProjectModal" onClick={this.appear}>
					{topSuccessMessage
						&& (
							<div className="success-message">
								<p>{topSuccessMessage}</p>
							</div>
						)}
					<CreateProjectForm
						onSubmit={this._onFormSubmitted}
						workspaces={workspaces}
						isCreatingProject={isCreatingProject}
						skipWorkspaceSelection={skipWorkspaceSelection}
						initialWorkspaceID={initialWorkspaceID}
					/>
				</div>
			</CloseableModalWithTitle>
		);
	}
}

CreateProjectModal.propTypes = {
	createProjectRequest: AsyncRequestType,
	onClose: PropTypes.func,
	createProject: PropTypes.func,
	resetCreateProjectRequest: PropTypes.func,
	workspaces: PropTypes.arrayOf( PropTypes.instanceOf( Workspace ) ),
	topSuccessMessage: PropTypes.string,
	onProjectCreationSuccess: PropTypes.func.isRequired,
	onProjectCreationFailed: PropTypes.func.isRequired,
	initialWorkspaceID: PropTypes.number
};

CreateProjectModal.defaultProps = {
	createProjectRequest: defaultAsyncRequest,
	onClose: undefined,
	createProject: undefined,
	resetCreateProjectRequest: undefined,
	workspaces: [],
	topSuccessMessage: undefined,
	initialWorkspaceID: undefined
};

export default connectComponent(
	( state, props ) => ( {
		createProjectRequest: state.createProjectRequest,
		workspaces: getCurrentUserWorkspacesWithProjectCreationPermissions( state, props ),
		currentUserOwnsAWorkspace: getUserOwnsAWorkspace( state )
	} )
)( CreateProjectModal );
